import React,{createRef} from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Loader, Message, ListItem, ListContent, List  } from "semantic-ui-react";
import {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
} from "../../../../../store/Page";
import api from "../../../../../api";
import "./BotBuilder.css";
import BranchList from "./BranchList";
import BranchDetail from "./BranchDetail";
import AddButton from "./AddButton";

import Modal from "../../../ChatSetup/components/Modal";

import config from "../../../../../config";
import AddSkills from "./AddSkills";
import QuestionResponse from "./QuestionResponse";
import MultipleChoice from "./MultipleChoice";
import AddLinks from "./AddLinks";
import AddWebhook from "./AddWebhook";
import AddCallForward from "./AddCallForward"
import ScheduledBranchCall from "./ScheduledBranchCall"
import CreateBranch from "./CreateBranch";
import LiveChatRule from "./LiveChatRule";
import TestView from "./TestView";
import ZipCodeRules from "./ZipCodeRules";
import TranscriptRule from "./TranscriptRule";
import DataParseRule from "./DataParseRule";
import AddGtmTriggerId from "./AddGtmTriggerId";
import ConfusedBranchDetails from "./confusedbranch/ConfusedBranchDetails";
import CustomErrorField from "../../../../Form/CustomErrorField";
import util_funs from "../../../../../util_funs";
import { transcriberLanguages } from "../../../../../util/transciberlanguage";

class BotBuilderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      list: [],
      editBranchType: null,
      integrationId: null,
      primaryBranchId: null,
      update_list: [],
      modalstatus: false,
      modalfooter: "",
      modaltitle: "",
      modalbody: "",
      rename: "",
      keyauto_id: "",
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
      workspace_id: "",
      branchList: [],
      captured_as: [],
      countryCodeLists: [],
      location_list: [],
      branchDetails: [],
      captured_list: [],
      activemodal: [],
      defaultCountryCodeLists: [],
      location_list: [],
      button_action_id: "add",
      deletemodalid: "",
      intent_data: [],
      active_branch_id: "",
      actionforgenaction:'',
      AIRulesData: [
        {
          cat_id: "",
          sub_cat_id: "",
          intent_id: "",
          intent_branch_id: "",
          intent_status: false,
        },
      ],
      loadingaction: false,
      toster: { color: "", status: false, message: "" },
      create_modal_status: "",
      branch_new_id: "",
      modalindex: "",
      livechatshow: false,
      editortrue: false,
      iframestatus: false,
      testView: false,
      default_gdf_workspace_id: '',
      alert_message_display: false,
      custom_workspace_id: "",
      zipcodenewbranch_id: "",
      checkzipcaptured: false,
      review_links: "",
      contact_bucket: [],
      checkedValue: [],
      checkedValueID: [],
      branchType: null,
      confusedButtonActive: 1,
      checkedCategory: [],
      checkedServiceCategory: [],
      trainYourAiData: null,
      isConfuseChanged: false,
      finalWorkspaceSaveModalFlag: false,
      showmodal: false,
      notemodalbody: '',
      reportcommnet: '',
      reportstatus: '',
      reporterror: false,
      canSubmit: true,
      modalstatusforBranch: false,
      voice_model: '',
      voice_model_error: false,
      puases_modal:false,
      puases_modalbody:'',
      puases_modalfooter:'',
      pauses_sound_list:[],
      usr_pauses_sound_list:[],
      pauses_inputs:0,
      Voicealert:'',
      Voicealerttype:'success',
      pause_text:'',
      showinputmodal:false,
      inputbody:'',
      form_Data:[],
      usr_pausesdata:false,
      pausetextfooter:"",
      modelzindex:999,
      view_pause_modal:false,
      pauses_list:[],
      view_pause_body:'',
      Voicealert1:'',
      oldTxt:'',
      modalBtn:false,
      turboList: [],
      transcriber_language: '',
      errorData: {},
      isAlertMessage: false,
      alertMessage: '',
      squadDeleted: []
    };
    this.formRef=createRef();
  }
  componentDidMount() {
    const { setPageTitle, toggleNavigation, toggleBreadcrum } = this.props;
    setPageTitle({
      title: "BotBuilder",
      navigationName: "workspace",
      subHeaderTitle: "Conversations",
    });
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });
    //alert( this.props.match.params.id)
    this.setState({ workspace_id: this.props.match.params.id }, function () {
      this.loadData();
      
    });
    // this.loadData();
    // this.loadData();
  }

  getAllTurboWorkspace = () => {
    this.setState({ loading: true });

    api.botbuilder.getSearchList({ search_val: '',workspace_type:'Voice_Turbo' }).then((data) => {
      try {
        this.setState({
          loading: false,
          turboList: data.data.list,
        });
        // check if y
      } catch (err) {
        console.log(err);        
      }
    });
  };

  updateBranchList = () => {
    api.botbuilder
    .viewwokspacedetail({ auto_id: this.state.workspace_id })
    .then((data) => {
      this.setState({
        branchList: data.data.branchLists,
      })
    })
  }


  loadData = () => {
    api.botbuilder
      .viewwokspacedetail({ auto_id: this.state.workspace_id })
      .then((data) => {
        if (data.status === 401) {
          // console.log(this.props.history)
          this.props.history.push('/notAllowed')
        }
        this.getVoiceModel();
        if (data.data.bot_type === 'Voice_Turbo') {
          this.getAllTurboWorkspace();
        }
        
        try {
          this.setState({
            branchList: data.data.branchLists,
            captured_as: data.data.captured_as,
            countryCodeLists: data.data.countryCodeLists,
            branchDetails: data.data.branch_details,
            defaultCountryCodeLists: data.data.defaultCountryCodeLists,
            location_list: data.data.location_list,
            captured_list: data.data.captured_list,
            userinfo: data.data.userinfo,
            bot_type: data.data.bot_type,
            response_time: data.data.response_time,
            voice_model: data.data.voice_model,
            transcriber_language: data.data.transcriber_language,
            intent_data: data.data.intent_data,
            gpt_intent_data: data.data.gpt_intent_data,
            active_branch_id: data.data.branch_details.branch_id,
            alert_message_display: data.data.alert_message_display,
            custom_workspace_id: data.data.custom_workspace_id,
            checkzipcaptured: data.data.branch_details?.checkzipcaptured,
            loading: false,
            originalData: data.data,
            review_links: data.data.reviews,
            contact_bucket: data.data.contact_bucket,
            //checkedValueID: data.data.branch_details.details.selected_buckets,
            //checkedValueID: data.data.CheckedValArr,
            checkedValue: data.data.checkedValue,
            branchType: data.data?.branch_details?.details?.trainyourAI,
          });
        
          this.getvoicePauses();
          this.modalClose();

          setTimeout(() => {
            this.setState({ userToaster: false });
          }, 3000);

        } catch (err) {
          console.log(err);
        }
      });
  };

  getVoiceModel = () => {
      const options = {
        method: 'GET', 
        headers: {
          'xi-api-key': '35739bdc170b179ab470cbe5cae09269'
        }
      };

    fetch('https://api.elevenlabs.io/v1/voices', options)
      .then(response => response.json())
      .then((response) => {
        const allListCloned = response.voices.filter((e) => e.category === 'cloned');

        // Add premade voices based on their voice IDs
        const premadeVoiceIds = ['cgSgspJ2msm6clMCkdW9', 'TX3LPaxmHKxFdv7VOQHJ', 'XrExE9yKIg1WjnnlVkGX','cjVigY5qzO86Huf0OWal','tnSpp4vdxKPjI9w0GnoV','kPzsL2i3teMYv0FxEYQ6','XfNU2rGpBa01ckF309OY','i4CzbCVWoqvD0P1QJCUL', 'gDnGxUcsitTxRiGHr904', 'QMSGabqYzk8YAneQYYvR', '1vHrrFQuLuyqEl17e9gl', 'Dh68koMHNSYl8A1jH9Je', 'ys3XeJJA4ArWMhRpcX1D'];
        const britishVoiceIds = ['gDnGxUcsitTxRiGHr904', 'QMSGabqYzk8YAneQYYvR', '1vHrrFQuLuyqEl17e9gl'];
        const australianVoiceIds = ['Dh68koMHNSYl8A1jH9Je', 'ys3XeJJA4ArWMhRpcX1D'];
        let premadeVoices = response.voices.filter((e) => premadeVoiceIds.includes(e.voice_id));
        premadeVoices.map( e => {
          e.name = e.name.split(" ")[0];
          // Append "(British)" if the voice ID is in the britishVoiceIds array
          if (britishVoiceIds.includes(e.voice_id)) {
            e.name += " (British)";
          }

          // Append "(Australian)" if the voice ID is in the australianVoiceIds array
          if (australianVoiceIds.includes(e.voice_id)) {
            e.name += " (Australian)";
          }
          return e;
        });
        
        // Combine cloned and premade voices
        const allVoices = [...allListCloned, ...premadeVoices];

        const voicePreviewUrls = [
          { voice_id: '0dFolLORX2FxBR3EtGm9', preview_url: 'https://ztvoicechat.s3.us-west-2.amazonaws.com/TurboVoice/User18/CA5642a9fc22535b4f0aa5b16084daffe5.mp3' },
          { voice_id: 'KhrpSyUwQm3FDpVF1EXy', preview_url: 'https://ztvoicechat.s3.us-west-2.amazonaws.com/TurboVoice/User18/CA5a01b7357295e869cf4416fd6d8dc8eb.mp3' },
          { voice_id: 'TX3LPaxmHKxFdv7VOQHJ', preview_url: 'https://ztvoicechat.s3.us-west-2.amazonaws.com/TurboVoice/User18/CAf77692ea9fa6d071f23d3d98eaba6410.mp3' },
          { voice_id: 'XrExE9yKIg1WjnnlVkGX', preview_url: 'https://ztvoicechat.s3.us-west-2.amazonaws.com/TurboVoice/User18/CA309b95376a2113994f8402d6ce08df9b.mp3' },
          { voice_id: 'cgSgspJ2msm6clMCkdW9', preview_url: 'https://ztvoicechat.s3.us-west-2.amazonaws.com/TurboVoice/User18/CA28ef353bbf07965d5903990892889700.mp3' },
          { voice_id: 'cjVigY5qzO86Huf0OWal', preview_url: 'https://ztvoicechat.s3.us-west-2.amazonaws.com/TurboVoice/User18/CAa3f822b094171ab88719dada5098a070.mp3' },
          { voice_id: '1vHrrFQuLuyqEl17e9gl', preview_url: 'https://ztvoicechat.s3.us-west-2.amazonaws.com/TurboVoice/User18/CA7a6b038baa1fb55065a2a797fe4f5cd8.mp3' },
          { voice_id: 'QMSGabqYzk8YAneQYYvR', preview_url: 'https://ztvoicechat.s3.us-west-2.amazonaws.com/TurboVoice/User18/CAa43cfadf7a62b0123b60b244bd61be17.mp3' },
          { voice_id: 'XfNU2rGpBa01ckF309OY', preview_url: 'https://ztvoicechat.s3.us-west-2.amazonaws.com/TurboVoice/User18/CAc15971d7ed1640b23cd90ca2c090d518.mp3' },
          { voice_id: 'gDnGxUcsitTxRiGHr904', preview_url: 'https://ztvoicechat.s3.us-west-2.amazonaws.com/TurboVoice/User18/CA4656364e3a1a5c749e3fbd37c8f4777a.mp3' },
          { voice_id: 'i4CzbCVWoqvD0P1QJCUL', preview_url: 'https://ztvoicechat.s3.us-west-2.amazonaws.com/TurboVoice/User18/CAfcd7e9e2413e734e4393ff41f2344bc6.mp3' },
          { voice_id: 'kPzsL2i3teMYv0FxEYQ6', preview_url: 'https://ztvoicechat.s3.us-west-2.amazonaws.com/TurboVoice/User18/CA9b79bc660706096b6e63d2ddafbfac1d.mp3' },
          { voice_id: 'tnSpp4vdxKPjI9w0GnoV', preview_url: 'https://ztvoicechat.s3.us-west-2.amazonaws.com/TurboVoice/User18/CAccc0db3a56850aafffe4abe442ea0d47.mp3' }
        ];
        
        // Replace preview_url in allVoices
        allVoices.forEach(voice => {
          const match = voicePreviewUrls.find(item => item.voice_id === voice.voice_id);
          if (match) {
            voice.preview_url = match.preview_url;
          }
        });
        

        if (this.state.voice_model) {
          const savedModel = allVoices.filter((e) => e.voice_id === this.state.voice_model);
          if (savedModel.length) {
            this.setState({
              voice_model_sample: savedModel[0].preview_url
            });
          }
        }
        this.setState({
          voice_model_list: allVoices
        });
      })
      .catch(err => console.error(err));
  }
  
  getvoicePauses=()=>{
    let ai='user_id='+util_funs.encrypt_url(this.state.userinfo.user_id)+"&workspace_id="+this.state.workspace_id;
    api.botbuilder
    .getvoicePauses(ai)
    .then((data) => { 
         if(data?.status){
          let list=data.data;
          if(list.length==0){
             let x={};
             x.id=undefined;
             x.voice_id=data.voices[0].id;
             x.pause_details=data.voices[0];
             list[0]=x;
          }
          this.setState({usr_pauses_sound_list:list,pauses_sound_list:[...data.voices,...data.user_pauses],pauses_list:data.user_pauses});
         }
    });
  }
  saveLoadingState = (loadingState) => {
    this.setState({
      loading: loadingState
    })
  }
  updateList = (data, index) => {
    // console.log("delete",data,index)
    let newintex = typeof index != "undefined" ? index : "";

    if (newintex != "" && newintex == this.state.active_branch_id) {
      let actid = ['GEN_WEB','GEN_VOICE'].includes(this.state.bot_type) ? data[0].branch_id: data[1].branch_id;

      this.setState({ branchList: data, activemodal: "" }, function () {
        this.editBranch(actid);
      });
    } else {
      this.setState({ branchList: data, activemodal: "" });
    }
  };

  saveButton = (data, type_id) => {
    data["branch_id"] = this.state.active_branch_id;
    data["user_id"] = "12333";
    data["workspace_id"] = this.state.workspace_id;
    
    // console.log(data);
    if (type_id == "add") {
      let branchDetails = this.state.branchDetails;
      branchDetails.branch_buttons.push(data);
      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    } else if (type_id == "order") {
      let branchDetails = this.state.branchDetails;
      branchDetails.branch_buttons = data;
      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    } else if (type_id == "validation") {
      let branchDetails = this.state.branchDetails;
      if (
        data.target.value ==
        this.state.originalData.branch_details.details.validation &&
        this.state.originalData.branch_details.details.validation != ""
      ) {
        branchDetails.details.validation_reply =
          this.state.originalData.branch_details.validation_reply;
      } else {
        branchDetails.details.validation_reply = [];
      }
      branchDetails.details.validation = data.target.value;
      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    } else if (type_id == "validationreply") {
      let branchDetails = this.state.branchDetails;
      if (branchDetails.details.validation == "phonenumber") {
        branchDetails.details.validation_reply = [];
        branchDetails.details.validation_reply.push(
          {
            phone_length_reply:
              document.getElementsByName("phone_length_reply")[0].value,
          },
          {
            invalid_phone_reply: document.getElementsByName(
              "invalid_phone_reply"
            )[0].value,
          }
        );
      }
      if (branchDetails.details.validation == "email") {
        branchDetails.details.validation_reply = [];
        branchDetails.details.validation_reply.push({
          email_reply: document.getElementsByName("email_reply")[0].value,
        });
      }
      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    } else if (type_id == "captured_as") {
      let branchDetails = this.state.branchDetails;
      branchDetails.details.capture_as = data.target.value;
      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    } else if (type_id == "is_end") {
      let branchDetails = this.state.branchDetails;
      branchDetails.details.is_end = data.target.checked ? "Y" : "";
      branchDetails.details.team_workspace_members = "0";
      branchDetails.details.team_profile = "1";
      branchDetails.team_data = [
        {
          branch_id: "",
          created_at: "",
          email: "",
          id: "",
          phone: "",
          user_id: "",
          workspace_id: "",
          country_code: "",
        },
      ];
      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    } else if (type_id == "team_profile") {
      let branchDetails = this.state.branchDetails;
      branchDetails.details.team_profile = data.target.checked ? "1" : "0";
      branchDetails.details.team_workspace_members = "0";
      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    } else if (type_id == "team_workspace_members") {
      let branchDetails = this.state.branchDetails;
      branchDetails.details.team_workspace_members = data.target.checked
        ? "1"
        : "0";
      branchDetails.details.team_profile = "0";
      branchDetails.team_data = [
        {
          branch_id: "",
          created_at: "",
          email: "",
          id: "",
          phone: "",
          user_id: "",
          workspace_id: "",
          country_code: "",
        },
      ];
      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    } else if (type_id == "capturedSave") {
      let listcaptured = this.state.captured_list;
      listcaptured.push(data.list);
      this.setState({ captured_list: listcaptured });
    } else if (type_id == "capturedDelete") {
      let listcaptured = this.state.captured_list;

      let activeindex = listcaptured.findIndex((list) => list.slug == data.key);

      listcaptured.splice(activeindex, 1);
      if (this.state.branchDetails.details.capture_as == data.key) {
        let details = this.state.branchDetails;
        details.details.capture_as = "";
        this.setState({ branchDetails: details });
      } else {
        this.setState({ captured_list: listcaptured });
      }
    } else if (type_id == "noAIselected") {
      let branchDetails = this.state.branchDetails;
      branchDetails.details.target_branch = data.target.value;

      var index = data.target.selectedIndex;
      var optionElement = data.target.childNodes[index];
      if (optionElement.length) {
        var attribute = optionElement.getAttribute("optiontext");
      } else {
        var attribute = "";
      }

      let optiontext = 0;
      if (typeof attribute != "undefined" && attribute != "") {
        optiontext = attribute;
      }

      branchDetails.details.target_branch_type = optiontext;

      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    } else if (type_id == "transcriptRule") {
      let branchDetails = this.state.branchDetails;
      branchDetails.details.transscript_rule = data.transscript_rule;
      branchDetails.details.trans_script_rule_button =
        data.trans_script_rule_button;
      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    } else if (type_id == "delete_transcript_rule") {
      let branchDetails = this.state.branchDetails;
      branchDetails.details.transscript_rule = "";
      branchDetails.details.trans_script_rule_button = "";
      this.setState({ branchDetails: branchDetails }, function () {
        //this.setState({ activemodal: "" });
      });
    }
    else if (type_id == "select_contact") {
      let branchDetails = this.state.branchDetails;
      branchDetails.details.select_contact = data.target.checked
        ? "1"
        : "0";
      this.setState({ branchDetails: branchDetails }, function () {
      });
    }
    else if (type_id == "show_country_code") {

      let branchDetails = this.state.branchDetails;
      branchDetails.details.show_country_code = data.target.checked
        ? "1"
        : "0";
      this.setState({ branchDetails: branchDetails }, function () {
      });
    }

    else {
      let branchDetails = this.state.branchDetails;
      branchDetails.branch_buttons[type_id] = data;
      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    }
  };

  actionTeamMembers = (data, action, index) => {
    if (action == "add") {
      let add_team_array = {
        branch_id: "",
        created_at: "",
        email: "",
        id: "",
        phone: "",
        user_id: "",
        workspace_id: "",
        country_code: "",
      };

      let branchDetails = this.state.branchDetails;
      branchDetails.team_data.push(add_team_array);
      this.setState({ branchDetails: branchDetails }, function () {
        this.setState({ activemodal: "" });
      });
    }

    if (action == "delete") {
      let branchDetails = this.state.branchDetails;
      branchDetails.team_data.splice(index, 1);

      this.setState({ branchDetails: branchDetails }, function () {
        this.modalClose();
      });
    }

    if (action == "email_change") {
      let branchDetails = this.state.branchDetails;
      branchDetails.team_data[index].email = data.target.value;
      this.setState({ branchDetails: branchDetails }, function () { });
    }

    if (action == "isd_code_change") {
      let branchDetails = this.state.branchDetails;
      branchDetails.team_data[index].country_code = data.target.value;
      this.setState({ branchDetails: branchDetails }, function () { });
    }

    if (action == "phone_change") {
      let branchDetails = this.state.branchDetails;

      branchDetails.team_data[index].phone = this.phoneFormat(
        data.target.value
      );

      this.setState({ branchDetails: branchDetails }, function () { });
    }

    if (action == "checkedValue") {
      let branchDetails = this.state.branchDetails;
      branchDetails.details.selected_buckets = data;
      this.setState({ branchDetails: branchDetails }, function () { });
    }

  };

  openTab = (tab) => {
    if (tab == "textarea") {
      let add_question_array = {
        auto_id: "",
        branch_id: "",
        question: "",
        status: "",
        id: "",
        user_id: "",
        workspace_id: "",
        country_code: "",
      };

      let branchDetails = this.state.branchDetails;
      branchDetails.branch_questions.push(add_question_array);
      this.setState(
        { branchDetails: branchDetails, editortrue: true },
        function () {
          this.setState({ activemodal: "" });
        }
      );
    } else {
      if (tab == "skills") {
        this.setState(
          {
            activemodal: tab,
            button_action_id: "add",
            AIRulesData: [
              {
                cat_id: "",
                sub_cat_id: "",
                intent_id: "",
                intent_branch_id: "",
                intent_status: false,
              },
            ],
          },
          function () {
            let scrolltop =
              document.querySelector(".rightbotbuilder").offsetTop -
              document.body.scrollTop;
            window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
          }
        );
      } else if (tab == "call_forward") {
        this.setState(
          { activemodal: tab },
          function () {
            let scrolltop =
              document.querySelector(".rightbotbuilder").offsetTop -
              document.body.scrollTop;
            window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
          }
        );
      } else if (tab == "branch_call_time") {
        this.setState(
          { activemodal: tab },
          function () {
            let scrolltop =
              document.querySelector(".rightbotbuilder").offsetTop -
              document.body.scrollTop;
            window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
          }
        );
      }else if( tab == "dataParse"){
        this.setState(
          { activemodal: tab },
          function () {
            let scrolltop =
              document.querySelector(".rightbotbuilder").offsetTop -
              document.body.scrollTop;
            window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
          }
        );
      }else{
        this.setState(
          { activemodal: tab, button_action_id: "add", branch_new_id: "" },
          function () {
            let scrolltop =
              document.querySelector(".rightbotbuilder").offsetTop -
              document.body.scrollTop;
            window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
          }
        );
      }
    }
  };
  popupClose = () => {
    this.setState({ activemodal: "" });
  };

  saveBranch = (data, callback) => {
    api.botbuilder.saveBranch({ bot_type: this.state.bot_type, ...this.state.branchDetails, checkedValue: this.state.checkedValue }).then((data) => {
      try {
        let branchList = this.state.branchList;
        let activeindex = branchList.findIndex(
          (list) => list.branch_id == this.state.active_branch_id
        );

        branchList[activeindex] = data.single_branch_data;

        let branch_details = this.state.branchDetails;
        branch_details.details.attachment_on_off =
          data.single_branch_data.attachment_on_off;
        this.setState(
          {
            branchList: branchList,
            branchDetails: branch_details,
            editortrue: false,
            alert_message_display: true,
          },
          function () {
            callback("success");
          }
        );
      } catch (err) {
        console.log(err);
      }
    });
  };

  buttonAction = (action, index) => {
    if (action == "edit") {
      // console.log(index)
      this.setState(
        { activemodal: "button", button_action_id: index },
        function () {
          let scrolltop =
            document.querySelector(".rightbotbuilder").offsetTop -
            document.body.scrollTop;
          window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
        }
      );
    }

    if (action == "delete") {
      this.popupClose();
      this.setState({
        modalstatus: true,
        modaltitle: "Confirm Your Action",
        modalbody: "Are you sure you want to delete?",
        deletemodalid: index,
      });
    }
  };

  skillsAction = (action, index, delete_ai_id) => {
    if (action == "edit") {
      let aidata = this.state.branchDetails.ai_list[index];

      let data = [
        {
          cat_id: aidata.cat_id,
          sub_cat_id: aidata.sub_cat_id,
          intent_id: aidata.intent_id,
          intent_branch_id: aidata.intent_branch_id,
          intent_status: true,
        },
      ];

      this.setState(
        {
          activemodal: "skills",
          button_action_id: delete_ai_id,
          AIRulesData: data,
        },
        function () {
          let scrolltop =
            document.querySelector(".rightbotbuilder").offsetTop -
            document.body.scrollTop;
          window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
        }
      );
    }

    if (action == "delete") {
      this.popupClose();
      api.botbuilder
        .deleteAIRules({ delete_ai_id: delete_ai_id })
        .then((data) => {
          try {
            let branchDetails = this.state.branchDetails;
            branchDetails.ai_list.splice(index, 1);
            this.setState({ branchDetails: branchDetails });
          } catch (err) {
            console.log(err);
          }
        });
    }
  };

  modalFooter = (index) => {
    if (index === "finalsave") {
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalClose()}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>No</span>
          </button>
          <button
            type="button"
            onClick={() => this.confirmFinalSave()}
            className="btn  linear-gradient yr-submitbtn btn-sm"
          >
            <span>Yes</span>
          </button>
        </div>
      );
    } else if (index === "multiplechoicedelete") {
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalClose()}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>No</span>
          </button>
          <button
            type="button"
            onClick={() => this.confirmdeleteMultipleChoiceQuestion()}
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Yes</span>
          </button>
        </div>
      );
    }else if(index === "deletepause") {
     
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalClose()}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>No</span>
          </button>
          <button
            type="button"
            onClick={() => this.confirmDelPause(this.state.deletemodalid)}
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Yes</span>
          </button>
        </div>
      );
    }else if(index === "deletedefaultpause") {
     
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalClose()}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>No</span>
          </button>
          <button
            type="button"
            onClick={() => this.confirmDelDefaultPause(this.state.deletemodalid)}
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Yes</span>
          </button>
        </div>
      );
    }  else {
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalClose()}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>No</span>
          </button>
          <button
            type="button"
            onClick={() => this.confirmDeleteButton(this.state.deletemodalid)}
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Yes</span>
          </button>
        </div>
      );
    }
  };
  puases_modalfooter = () => {
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.closepoauses_model()}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>Cancel</span>
          </button>
          <button
            type="button"
            onClick={ (e) => { this.formRef.current.dispatchEvent(new Event("submit")); } }
            className="btn  linear-gradient yr-submitbtn btn-sm"
          >
            <span>Save Changes</span>
          </button>
         
        </div>
      );
    
  };
  savepauses_config=(e)=>{
    e.preventDefault();
    var savedata = new FormData(e.target);
    let formObject =[...savedata.entries()];
  
   if(formObject.length>0){
    this.setState({loading:true},()=>{ let body=this.pausessoundmodalbody();
      this.setState({puases_modalbody:body});
    });
    
    
    
    let params={sounds:formObject,workspace_id: this.state.workspace_id,user_id:util_funs.encrypt_url(this.state.userinfo.user_id)};
       api.botbuilder.savevoicePauses(params).then(data=>{
            try{
             if(data.status){
                this.setState({Voicealert:'Data saved successfully',usr_pausesdata:false,Voicealerttype:'green',loading:false},()=>{ let body=this.pausessoundmodalbody();
                  this.setState({puases_modalbody:body});
                });
                  setTimeout(() => {
                    this.setState({Voicealert:''},()=>{ let body=this.pausessoundmodalbody();
                      this.setState({puases_modalbody:body});
                    });
                    this.closepoauses_model();
                  }, 3000);     
              }else{
                this.setState({Voicealert:data.message,Voicealerttype:'red',loading:false},()=>{ let body=this.pausessoundmodalbody();
                  this.setState({puases_modalbody:body});
                });
                setTimeout(() => {
                  this.setState({Voicealert:''},()=>{ let body=this.pausessoundmodalbody();
                    this.setState({puases_modalbody:body});
                  });
                }, 3000);     
             }
            
            } catch (err) {
              console.log(err);
              this.setState({loading:false,usr_pausesdata:false});
            }
       });
   }else{
    this.setState({Voicealert:"No change",Voicealerttype:'red'},()=>{ let body=this.pausessoundmodalbody();
      this.setState({puases_modalbody:body});
    });
    setTimeout(() => {
      this.setState({Voicealert:''},()=>{ let body=this.pausessoundmodalbody();
        this.setState({puases_modalbody:body});
      });
    }, 3000);   
   }

  }
  pausessoundmodalbody=()=>{
    let maxlen=30;
  
    return(<div className="container">
       {this.state.loading && <div className="ui loading form"></div>}
      <div className="row">
      {this.state.Voicealert && <div style={{width:'100%'}} className={this.state.Voicealerttype=='green' ? 'alert alert-success':'alert alert-danger'}>{this.state.Voicealert}</div>}
        <div className="col-md-12">
          <p>Grammatical pauses help improve the conversational experience and hide latency between responses. By default, we utilize a typing sound effect, but you can use grammatical pause like "let's see here" or "One moment" to help the conversation flow more naturally.</p>
        </div>
        <div style={{width:'100%'}}><p  className="pull-right"><span onClick={(e)=>this.viewpauses_modal(e)} style={{fontWeight:'500',cursor:'pointer'}}><i className="fa fa-eye"></i> View pause list</span>&nbsp; &nbsp;<span  style={{fontWeight:'500',cursor:'pointer'}} onClick={(e)=>this.openTextForm(e)}>+Add your pause text</span></p></div>
      </div>
      <div className="p-5">
      <form ref={this.formRef} className="form"  onSubmit={(e) => this.savepauses_config(e)}>

      {this.state.usr_pauses_sound_list.length>0 &&
          <>{this.state.usr_pauses_sound_list.map((usr_sound,key)=>{
            return(<div className="row"  key={key+Math.random()}><div className="col-md-3">
          <label>Pause #{key+1}</label> </div>
        <div className="col-md-4">
        <select name={`user_sound_${usr_sound?.id}`}  key={key} dtype="2" onChange={(e)=>this.updateSoundlist(e,key,1)} defaultValue={usr_sound.voice_id}  className="form-control">
        <option value="select">Select pause</option>
        {this.state.pauses_sound_list.map((sound,it)=>{
          let txt=sound?.name;
          if (txt.length > maxlen) {
            txt= txt.substr(0, maxlen) + '...';  
          }
           return(<option  value={sound?.id} key={it}>{txt}</option>);
          })
        }
        
        </select>
        </div>
        <div className="col-md-4"> <audio id={`audio_${key}`}  style={{width:'100%'}} key={usr_sound.voice_id} controls onPlay={(e)=>this.playAll(e,key)} >
                                        <source  src={`${usr_sound?.pause_details?.filepath}`} type="audio/mp3" />
                                        Your browser does not support the audio element.
                                      </audio></div>
          {this.state.usr_pauses_sound_list.length>1 && <div className="col-md-1"> 
          <i onClick={(e)=>this.deleteSound(e,usr_sound.id,key)} className="la la-trash pull-right btn red" style={{cursor: 'pointer'}}></i>
          </div>}
         
        </div>);
            } 
            )
            }</>
        
         }
     
          </form>
           <p><a onClick={()=>this.addInputfield()} style={{cursor:'pointer',fontWeight:'500'}}>+Add additional pauses</a></p>
        
      </div>
          
         <div className="row">
            <p><strong>*NOTE:</strong> After your last pause is used, they will repaet in the same order until the conversation completes.</p>
          </div>
        
      
   </div>);
  }
  playAll=(e,id)=>{
   
    e.preventDefault();
  
      var audios = document.getElementsByTagName('audio');
      for(var i = 0, len = audios.length; i < len;i++){
          if(audios[i] != e.target){
              audios[i].pause();
          }
      }
  }
  confirmDelPause=(iddata)=>{
    console.log(iddata,'dd')
    let n=1;
    let {key,id}=iddata;
    if(typeof id=='undefined'){
      let usrlist=this.state.usr_pauses_sound_list;
     
      usrlist=usrlist.filter((it,i)=>i!==key);
      
     this.setState({pauses_inputs:n,
                    usr_pauses_sound_list:usrlist,
                    modaltitle: "",
                  modalstatus: false,
                  modalbody: "",
                  modelzindex:9999,
                  modalindex:'',
     deletemodalid:''},()=>{ let body=this.pausessoundmodalbody();
       this.setState({puases_modalbody:body});
    });
   }else{
     this.setState({loading:true},()=>{ let body=this.pausessoundmodalbody();
       this.setState({puases_modalbody:body});
    });
     api.botbuilder
           .deletePause({
            id:id
           })
           .then((data) => {
             try {
              let userlist=this.state.usr_pauses_sound_list;
              let newlist=userlist.filter(item=>item.id!==id);
              this.setState({usr_pauses_sound_list:newlist,
                loading:false,
                  Voicealert:"Data deleted successfully.",
                  Voicealerttype:'green',
                 modaltitle: "",
                modalstatus: false,
                modalbody: "",
                modelzindex:9999,
                modalindex:'',
                deletemodalid:''
            },()=>{ let body=this.pausessoundmodalbody();
                this.setState({puases_modalbody:body});
             });
             
             setTimeout(() => {
               this.setState({Voicealert:''},()=>{ let body=this.pausessoundmodalbody();
                 this.setState({puases_modalbody:body});
               });
             }, 4000); 

             }catch(err){
               console.log(err);
               this.setState({loading:false});
             }
 
             });
   }
  }

  confirmDelDefaultPause=(id)=>{
     this.setState({
      loading:true,
      modaltitle: "",
      modalstatus: false,
      modalbody: "",
      modelzindex:9999,
      modalindex:'',
      deletemodalid:''},()=>{ 
      let body=this.viewpauses_listbody();
       this.setState({view_pause_body:body});
    });
    let checks=[];
    if(this.state.usr_pauses_sound_list.length){
      checks=this.state.usr_pauses_sound_list.filter((item)=>item.voice_id==id);
    }
     
    if(checks.length==0){
      api.botbuilder
      .deletedefaultPause({
       id:id,
       workspace_id:this.state.workspace_id
      })
      .then((data) => {
        
        try {
         let userlist=this.state.pauses_sound_list;
         let finallist=this.state.pauses_list.filter(item=>item.id!==id);
         let newlist=this.state.pauses_sound_list.filter(item=>item.id!==id);
         this.setState({
            loading:false,
            pauses_list:finallist,
            pauses_sound_list:newlist,
            Voicealert1:"Data deleted successfully.",
            Voicealerttype:'green',
            modaltitle: "",
            modalstatus: false,
            modalbody: "",
            modelzindex:9999,
            modalindex:'',
            deletemodalid:''
       },()=>{  let body=this.viewpauses_listbody();
        let body1=this.pausessoundmodalbody();
        this.setState({view_pause_body:body,puases_modalbody:body1});
     });
        
        setTimeout(() => {
          this.setState({Voicealert1:'' },()=>{ 
            let body=this.viewpauses_listbody();
       this.setState({view_pause_body:body});
    });
        }, 3000); 

        }catch(err){
          console.log(err);
          this.setState({loading:true},()=>{ 
            let body=this.viewpauses_listbody();
             this.setState({view_pause_body:body});
          });
        }

        });
    }else{
        this.setState({Voicealert1:'Please unselect this pause.',modaltitle: "",
        modalstatus: false,
        modalbody: "",Voicealerttype:'red'},()=>{ 
          let body=this.viewpauses_listbody();
        this.setState({view_pause_body:body});
      });
          setTimeout(() => {
        this.setState({Voicealert1:''},()=>{ 
          let body=this.viewpauses_listbody();
        this.setState({view_pause_body:body});
      });
      }, 3000); 
    }
     
   
  }

  deleteSound=(e,id,key)=>{
    this.setState({
      modaltitle: "Confirm Your Action",
      modalstatus: true,
      modalbody: "Are you sure want to delete this ?",
      modelzindex:999999,
      modalindex:'deletepause',
      deletemodalid:{id:id,key:key}
    });
  }
  deleteDefaultSound=(e,id,key)=>{
    let checks=[];
    if(this.state.usr_pauses_sound_list.length){
      checks=this.state.usr_pauses_sound_list.filter((item)=>item.voice_id==id);
    }
    if(checks.length==0){
      this.setState({
        modaltitle: "Confirm Your Action",
        modalstatus: true,
        modalbody: "Are you sure want to delete this ?",
        modelzindex:999999,
        modalindex:'deletedefaultpause',
        deletemodalid:id
      });
    }else{
      this.setState({Voicealert1:"This pause is already in use. Please unselect it before deleting it.",
      Voicealerttype:'red'},()=>{
        let body=this.viewpauses_listbody();
        this.setState({view_pause_body:body});
      });
      setTimeout(() => {
        this.setState({Voicealert1:'' },()=>{ 
          let body=this.viewpauses_listbody();
     this.setState({view_pause_body:body});
  });
      }, 3000);  
    }
    
  }

  viewpauses_listbody=(e)=>{
    return(<>{this.state.Voicealert1 && <div style={{width:'100%'}} className={this.state.Voicealerttype=='green' ? 'alert alert-success':'alert alert-danger'}>{this.state.Voicealert1}</div>}
    {this.state.pauses_list.length>0 ?  <List divided verticalAlign='middle'>
    {this.state.pauses_list.map((item,i)=>(<ListItem key={i}><div className="row"><div className="col-md-5"><strong>{item.name}</strong></div><ListContent className="col-md-4"><audio id={`audio_${i}`}  style={{width:'100%'}} key={item.id} controls onPlay={(e)=>this.playAll(e,i)}>
                                    <source  src={`${item.filepath}`} type="audio/mp3" />
                                    Your browser does not support the audio element.
                                  </audio></ListContent><div className="col-md-2"> <i onClick={(e)=>this.deleteDefaultSound(e,item.id,i)} className="la la-trash pull-right btn red" style={{cursor: 'pointer'}}></i></div></div></ListItem>))}
    </List> :<p>No pause found</p>
    }
    </>
    );
}

modalpauselistClose=()=>{
  this.setState({
    view_pause_modal: false,
    view_pause_body: '',
    modalindex:'',
  });
} 

viewpauses_modal=(e)=>{
   let body=this.viewpauses_listbody();
   this.setState({
    view_pause_modal: true,
    view_pause_body: body,
    modelzindex:55566,
    modalindex:'viewpause',
  });
}
  addInputfield=()=>{
    let input=this.state.pauses_inputs;
    let defaults=this.state.pauses_sound_list;
    let sound_list=this.state.usr_pauses_sound_list;
         let obj=defaults.filter((paus)=>paus.id==1);
           let update={};
           update['pause_details']=obj[0];
           update['voice_id']=obj[0].id;
            sound_list.push(update);

         this.setState({usr_pauses_sound_list:sound_list,pauses_inputs:input,usr_pausesdata:true},()=>{
           let body=this.pausessoundmodalbody();
           this.setState({puases_modalbody:body});
         });
    
  }
  openpuasesconfig=()=>{
    let body=this.pausessoundmodalbody();
   let footer=this.puases_modalfooter();
   this.setState({puases_modal:true,puases_modalbody:body,puases_modalfooter:footer});
  }
  updateSoundlist=(e,id,type)=>{
   let input=this.state.pauses_inputs;
     let selected=e.target.value;
     let defaults=this.state.pauses_sound_list;
     let sound_list=this.state.usr_pauses_sound_list;
     if(selected!=='select'){
          let obj=defaults.filter((paus)=>paus.id==selected);
          if(type==1){
            let search=sound_list[id];
            console.log("d",search,id,type);
            let update=search;
            update['pause_details']=obj[0];
            update['voice_id']=obj[0].id;
            sound_list[id]=update;
          }else{
            let update={};
            update['pause_details']=obj[0];
            update['voice_id']=obj[0].id;
             sound_list.push(update);
            //  input=input-1;
          }

          this.setState({usr_pauses_sound_list:sound_list,pauses_inputs:input,usr_pausesdata:true},()=>{
            let body=this.pausessoundmodalbody();
            this.setState({puases_modalbody:body});
          });
     }
  }
  closepoauses_model=()=>{
    this.setState({puases_modal:false,puases_modalfooter:'',puases_modalbody:''});
  }
  modalFooterforBranch = (index) => {

    return (
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => this.modalClose()}
          className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
          data-dismiss="modal"
        >
          <span>No</span>
        </button>
        <button
          type="button"
          onClick={() => this.confirmDeleteBranch(this.state.deletemodalid)}
          className="btn linear-gradient yr-submitbtn btn-sm"
        >
          <span>Yes</span>
        </button>
      </div>
    );

  };
  confirmDeleteButton = (id) => {
    let branchDetails = this.state.branchDetails;
    //   branchDetails.branch_buttons[type_id]=data
    // let activeindex = branchDetails.branch_buttons.findIndex(
    //   (list) => list === buttoninfo.button_respond_on
    // );

    branchDetails.branch_buttons.splice(id, 1);

    this.setState({ branchDetails: branchDetails }, function () {
      this.modalClose();
    });

    //alert(id)
  };
  modalClose = () => {
    this.setState({
      modalstatus: false,
      modalstatusforBranch: false,
      modaltitle: "",
      modalbody: "",
      modalindex: "",
    });
  };
  finalWorkspaceSaveModal = () => {
    this.setState({ finalWorkspaceSaveModalFlag: false });
  }
  modalCloseCreate = () => {
    this.setState({
      create_modal_status: "",
    });
  };

  onDeploy = (action) => {
    
    if (action == "Open") {
      let msg = "";
      if ((this.state.userinfo.default_gdf_workspace_id != "0" &&
        this.state.userinfo.default_gdf_workspace_id != null &&
        this.state.userinfo.default_gdf_workspace_id ==
        this.state.custom_workspace_id &&
        this.state.bot_type == "GDF") ||
        (this.state.userinfo.default_workspace_id != "0" &&
          this.state.userinfo.default_workspace_id != null &&
          this.state.userinfo.default_workspace_id ==
          this.state.custom_workspace_id &&
          this.state.bot_type == "WEB") ||
        (this.state.userinfo.default_sms_workspace_id != "0" &&
          this.state.userinfo.default_sms_workspace_id != null &&
          this.state.userinfo.default_sms_workspace_id ==
          this.state.custom_workspace_id &&
          this.state.bot_type == "SMS") ||
        (this.state.userinfo.default_fb_workspace_id != "0" &&
          this.state.userinfo.default_fb_workspace_id != null &&
          this.state.userinfo.default_fb_workspace_id ==
          this.state.custom_workspace_id &&
          this.state.bot_type == "FB") || (
          this.state.userinfo.default_voice_workspace_id ==
          this.state.custom_workspace_id &&
          this.state.bot_type == "Voice"
        ) || (
          this.state.userinfo.default_general_voice_workspace ==
          this.state.custom_workspace_id &&
          this.state.bot_type == "GEN_VOICE"
        )|| (
          this.state.userinfo.default_general_web_workspace ==
          this.state.custom_workspace_id &&
          this.state.bot_type == "GEN_WEB"
        )
      ) {
        this.setState({ deployAction: "Remove" });
        msg = "Are you sure want to reset this workspace from default chatbot?";
      } else {
        if((this.state.bot_type === 'Voice' || this.state.bot_type === 'GEN_VOICE' || this.state.bot_type === 'Voice_Turbo')  && !this.state.voice_model){
          this.setState({
            voice_model_error : true
          })
          return;
        }
        this.setState({ deployAction: "Deploy" });
        if( this.state.bot_type == 'Voice_Turbo'){
          msg =
          "By deploying, this will update turbo conversation context. Are you sure this is what you want?";
        }else{
          msg =
          "By deploying, you will lose all of your Service titan and Zapier saved details, and this workspace will become your default bot. Are you sure this is what you want?";
        }
        
      }
      
        this.setState({
          dialogDeployTitle: "Confirm Your Action",
          dialogDeploy: true,
          dialogDeployMessage: msg,
        });
      
      
    }
    
    if (action == "Close") {
      this.setState({
        dialogDeploy: false,
        dialogDeployMessage: "",
        dialogDeployTitle: "",
		  actionforgenaction:""
      });
    }
    if(this.state.bot_type == 'GEN_WEB' || this.state.bot_type == 'GEN_VOICE'){
      let messageI = "";
      if(action == "Deploy"){
        messageI = "Please save your workspace before deploying it";
      }else{
        messageI = "Clicking on the Remove will disconnect your workspace. Do you want to Continue?";
      }
     
      this.setState({
        dialogDeploy: true,
        dialogDeployMessage: messageI,
        dialogDeployTitle: "Confirm Your Action",
        actionforgenaction:action,
      });
    }else{
      if (action == "Deploy" || action == "Remove") {
        let st;
        if (action == "Deploy") st = "1";
        if (action == "Remove") st = "0";
        api.botbuilder
          .deployworkspace({
            wid: this.state.workspace_id,
            status: st,
            bt: this.state.bot_type,
          })
          .then((data) => {
            try {
              let updatedata = this.state.userinfo;
              if (this.state.bot_type == "WEB")
                updatedata.default_workspace_id = data.data.default_workspace_id;
              if (this.state.bot_type == "SMS")
                updatedata.default_sms_workspace_id =
                  data.data.default_sms_workspace_id;
              if (this.state.bot_type == "FB")
                updatedata.default_fb_workspace_id =
                  data.data.default_fb_workspace_id;
              if (this.state.bot_type == "GDF")
                updatedata.default_gdf_workspace_id =
                  data.data.default_gdf_workspace_id;
              if (this.state.bot_type == "Voice") {
                updatedata.default_voice_workspace_id =
                  data.data.default_voice_workspace_id;
              }
              if (this.state.bot_type == "Voice_Turbo") {
                updatedata.voice_turbo_default_workspace_id =
                  data.data.voice_turbo_default_workspace_id;
              }
              if (this.state.bot_type == "GEN_VOICE") {
                updatedata.default_general_voice_workspace =
                  data.data.default_general_voice_workspace;
              }
              if (this.state.bot_type == "GEN_WEB") {
                updatedata.default_general_web_workspace =
                  data.data.default_general_web_workspace;
              }
              this.setState({
                dialogDeploy: false,
                dialogDeployMessage: "",
                userinfo: updatedata,
              });
            } catch (err) {
              console.log(err);
            }
          });
      }
    }
    
  };

  geralbotOndeploy = () => {
	let st;
	let action = this.state.actionforgenaction;
	if (action == "Deploy") st = "1";
	if (action == "Remove") st = "0";
	api.botbuilder
	.deployworkspace({
		wid: this.state.workspace_id,
		status: st,
		bt: this.state.bot_type,
	})
	.then((data) => {
		try {
		let updatedata = this.state.userinfo;
		if (this.state.bot_type == "WEB")
			updatedata.default_workspace_id = data.data.default_workspace_id;
		if (this.state.bot_type == "SMS")
			updatedata.default_sms_workspace_id =
			data.data.default_sms_workspace_id;
		if (this.state.bot_type == "FB")
			updatedata.default_fb_workspace_id =
			data.data.default_fb_workspace_id;
		if (this.state.bot_type == "GDF")
			updatedata.default_gdf_workspace_id =
			data.data.default_gdf_workspace_id;
		if (this.state.bot_type == "Voice") {
			updatedata.default_voice_workspace_id =
			data.data.default_voice_workspace_id;
		}
		if (this.state.bot_type == "GEN_VOICE") {
			updatedata.default_general_voice_workspace =
			data.data.default_general_voice_workspace;
		}
		if (this.state.bot_type == "GEN_WEB") {
			updatedata.default_general_web_workspace =
			data.data.default_general_web_workspace;
		}
		this.setState({
			dialogDeploy: false,
			dialogDeployMessage: "",
			userinfo: updatedata,
		});
		} catch (err) {
		console.log(err);
		}
	});
	this.setState({
		dialogDeploy: false,
		dialogDeployMessage: "",
		dialogDeployTitle: "",
		actionforgenaction:"",

	});
	
  }

  show_user_iframe = (chat_key, workspace_id) => {
    if (this.state.iframestatus == false) {
      let flag = "0";
      if (
        this.state.branchList.length > 0 &&
        this.state.branchList[0].status == "1"
      )
        flag = "1";

      if (flag == "0") {
        this.setState({
          dialogDeployTitle: "Alert",
          dialogDeploy: true,
          dialogDeployMessage:
            'Before deploy you have to save your workspace.please click on the "Save" button before deploy.',
          deployAction: "",
        });
      } else {
        this.setState({ iframestatus: true });
        var chatActivity = {};
        chatActivity.info = {};
        chatActivity.info.zipcode = "";
        chatActivity.info.price = "";
        chatActivity.info.propertyId = "";
        var this_js_script = document.getElementById("chatBT");
        var chatKey = chat_key;
        if (typeof chatKey === "undefined") {
          var chatKey =
            "some problem in chat bot code please contact to zyra administrator.";
          alert(chatKey);
        }
        // For UTM
        var documentUrl = document.URL;
        var n = documentUrl.indexOf("utm_source");
        if (n !== -1) {
          var utm_url = encodeURIComponent(documentUrl);
        } else {
          var utm_url = "";
        }

        var referrer_url = document.referrer;

        var css = "";
        css +=
          ".BOT_chatcontainer{border: none;display: block;position: fixed;top: auto;left: auto;bottom: 20px;right: 20px;visibility: visible;z-index: 214748364;max-height: 700px;transition: none 0s ease 0s;background: none transparent;opacity: 1;height: 90% !important;width: 400px !important;}@media only screen and (max-width: 480px){.BOT_chatcontainer {width: 100% !important;right: 0;bottom: 0;height: 100% !important;}}";
        //css += '.BOT_chatcontainer_Add{width: 185px !important; height:95px !important;}'
        var head = document.head || document.getElementsByTagName("head")[0],
          style = document.createElement("style");
        style.type = "text/css";
        if (style.styleSheet) {
          style.styleSheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }
        head.appendChild(style);
        var chatDiv = document.createElement("div");
        chatDiv.id = "zychatsection";
        if (document.body.firstChild) {
          document.body.insertBefore(chatDiv, document.body.firstChild);
        } else {
          document.body.appendChild(chatDiv);
        }

        var randomNumber = Math.floor(Math.random() * 100000000000000000);

        document.getElementById("zychatsection").innerHTML =
          '<iframe allow="autoplay" bottype="backend" frameborder="0" class="BOT_chatcontainer BOT_chatcontainer_Add" src="' +
          config.apiBaseURL.split("api")[0] +
          "contractorschatbot/index.html?chatKey=" +
          chatKey +
          "&hostName=" +
          encodeURIComponent(window.location.href) +
          "&referrer_url=" +
          referrer_url +
          "&utm_url=" +
          utm_url +
          "&documentUrl=" +
          documentUrl +
          "&randomNumber=" +
          randomNumber +
          "&workspace_id=" +
          workspace_id +
          ' " id="zychatObject"></iframe>';
        var eventMethod = window.addEventListener
          ? "addEventListener"
          : "attachEvent";
        var eventer = window[eventMethod];
        var messageEvent =
          eventMethod == "attachEvent" ? "onmessage" : "message";
        eventer(
          messageEvent,
          function (e) {
            var key = e.message ? "message" : "data";
            var data = e[key];
            console.log(key);
            if (typeof data == "string") {
              if (data == "open") {
                document
                  .getElementById("zychatObject")
                  .classList.remove("BOT_chatcontainer_Add");
                var receiver =
                  document.getElementById("zychatObject").contentWindow;
                // var gs=visitData.get();
                let obs = { ev: 'openclose', gs: {} };
                // receiver.postMessage("openclose", "*");
                receiver.postMessage(obs, "*");
              } else if (data == "close") {
                document
                  .getElementById("zychatObject")
                  .classList.add("BOT_chatcontainer_Add");
                var receiver =
                  document.getElementById("zychatObject").contentWindow;
                receiver.postMessage({ ev: 'openclose', gs: {} }, "*");
                // receiver.postMessage("openclose", "*");
              } else if (data == "Onload") {
                var receiver =
                  document.getElementById("zychatObject").contentWindow;
                receiver.postMessage(chatActivity, "*");
              } else if (data.split("_")[0] == "return") {
                //  try {
                //      parent.dataLayer.push({'event': 'gtm.click', 'gtm.elementId': data.split("_")[1]});
                //      try{
                //          if (typeof gtag !='undefined' &&  typeof gtag === "function") {
                //              if ( data.split("_")[1] == 'ChatEndID' ){gtag('event', 'Zyratalk Completed Chat', {'event_category': 'click', 'event_label': '','value': 0});}
                //          }
                //      }catch(ex){console.log(ex);}
                //  } catch (e) {
                //  }
              } else if (data.split("_")[0] == "iframresize") {
                var css2 =
                  ".BOT_chatcontainer_Add{width: 400px !important; height:" +
                  data.split("_")[2] +
                  "px !important;}";
                style.appendChild(document.createTextNode(css2));
              } else if (data == "dynamicCall") {
                try {
                  if (
                    typeof document.querySelectorAll('a[href^="tel:"]') !=
                    "undefined" &&
                    document.querySelectorAll('a[href^="tel:"]').length > 0
                  ) {
                    var dynamicCall = document
                      .querySelectorAll('a[href^="tel:"]')[0]
                      .href.replace("tel:", "");
                  } else {
                    var dynamicCall = "";
                  }
                  var receiver =
                    document.getElementById("zychatObject").contentWindow;
                  receiver.postMessage("dynamicCallReturn#" + dynamicCall, "*");
                } catch (e) { }
              } else if (data.split("_")[0] == "dataattrid") {
                try {
                  if (typeof data.split("_")[1] != "undefined") {
                    var receiver = document
                      .getElementById("zychatObject")
                      .setAttribute("data-chat-id", data.split("_")[1]);
                  }
                } catch (e) { }
              } else if (data == "hideChatOnPage") {
                var myobj = document.getElementById("zychatObject");
                myobj.remove();
              } else if (data.split("_")[0] == "toggleIframSize") {
                let newData = JSON.parse(data.split("_")[1]);
                if (parseInt(newData.width) <= 52) {
                  newData.width = 70;
                  newData.height = 70;
                }
                var css2 =
                  ".BOT_chatcontainer_Add{width: " +
                  newData.width +
                  "px !important; height:" +
                  newData.height +
                  "px !important;}";
                style.appendChild(document.createTextNode(css2));
              }
            }
          },
          false
        );
      }
    }
  };

  editBranch = (branch_id, branchType, isContextSaved = false) => {
    this.setState({integrationId:branch_id})
    if(isContextSaved){
      this.setState({
        finalWorkspaceSaveModalFlag: true
      });
      return;
    }
    if (branchType == "confused") {
      this.setState({ editBranchType: branch_id })
    } else {
      this.setState({ editBranchType: null })
    }
    const elems = document.querySelectorAll('.borderset');
    // console.log('elems', elems);
    elems.forEach((ele) => {
      ele.style.border = "none";
    });
    const elementbr = document.querySelector(
      'div[data-branch="' + branch_id + '"]'
    );
    elementbr.style.border = "1px solid rgb(15, 98, 254)";
    api.botbuilder.editBranch({ branch_id: branch_id, workspace_id: this.state.workspace_id }).then((data) => {
      try {
        // console.log(data)
        this.setState(
          {
            branchDetails: data.branch_details,
            active_branch_id: branch_id,
            activemodal: "",
            editortrue: false,
            checkzipcaptured: data.branch_details.checkzipcaptured,
            //checkedValueID: data.data.branch_details.details.selected_buckets,
            //checkedValueID: data.data.CheckedValArr,
            checkedValue: data.checkedValue,
            branchType: branchType
          },
          function () {
            let scrolltop =
              document.querySelector(".addEditBranch").offsetTop -
              document.body.scrollTop;
            window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
          }
        );
      } catch (err) {
        console.log(err);
      }
    });
  };

  saveAIRules = (data, selected_branch_id, callback) => {
    new Promise((resolve) => {
      data.forEach((element, index) => {
        //delete element[index]['categories];
        delete element["categories"];
        delete element["subcategories"];
        delete element["intents"];
      });
      resolve(data);
    }).then((data) => {
      let params = {
        data: data,
        selected_branch_id: selected_branch_id,
        branch_id: this.state.active_branch_id,
        workspace_id: this.state.workspace_id,
      };
      api.botbuilder.saveAIRules(params).then((data) => {
        try {
          let branch_detail = this.state.branchDetails;
          branch_detail.ai_list = data.list;
          this.setState({ branchDetails: branch_detail });
          callback("success");
        } catch (err) {
          console.log(err);
        }
      });
    });
  };

  updateAIRules = (data, edit_id, callback) => {
    //alert(edit_id)

    new Promise((resolve) => {
      data.forEach((element, index) => {
        //delete element[index]['categories];
        delete element["categories"];
        delete element["subcategories"];
        delete element["intents"];
      });
      resolve(data);
    }).then((data) => {
      let params = {
        data: data,
        branch_id: this.state.active_branch_id,
        workspace_id: this.state.workspace_id,
        edit_id: edit_id,
      };
      api.botbuilder.updateAIRules(params).then((data) => {
        try {
          let branch_detail = this.state.branchDetails;
          branch_detail.ai_list = data.list;
          this.setState({ branchDetails: branch_detail });
          callback("success");
        } catch (err) {
          console.log(err);
        }
      });
    });
  };

  textAreaAction = (data, action, index) => {
    if (action == "delete") {
      let branchDetails = this.state.branchDetails;
      branchDetails.branch_questions.splice(index, 1);

      this.setState({ branchDetails: branchDetails }, function () {
        this.modalClose();
      });
    }

    if (action == "textchange") {
      let branchDetails = this.state.branchDetails;

      branchDetails.branch_questions[index].question = data;
      this.setState({ branchDetails: branchDetails }, function () { });
    }

    if (action == "addtitle") {
      let branchDetails = this.state.branchDetails;

      branchDetails.details.branch_name = data.target.value;
      this.setState({ branchDetails: branchDetails }, function () { });
    }
  };

  addBranch = () => {
    let params = { workspace_id: this.state.workspace_id };

    this.setState({ loadingaction: true }, function () {
      api.botbuilder.createBranch(params).then((data) => {
        let branch_id = data.data[0].branch_id;
        let branchList = this.state.branchList;
        branchList.push(data.data[0]);

        this.setState(
          {
            branchDetails: data.branch_details,
            active_branch_id: branch_id,
            branchList: branchList,
            loadingaction: false,
            branch_new_id: branch_id,
            toster: {
              color: "green",
              status: true,
              message: "Branch addedd successfully.",
            },
          },
          function () {
            setTimeout(
              function () {
                this.setState({
                  toster: { color: "", status: false, message: "" },
                });
              }.bind(this),
              3000
            );
          }
        );
      });
    });
  };

  createNewBranch = (modal) => {
    let params = { workspace_id: this.state.workspace_id };

    this.setState({ create_modal_status: modal }, function () { });
  };
  saveNewBranch = (data, button, callback) => {
    let params = { workspace_id: this.state.workspace_id, data: data };
    api.botbuilder.addNewBranch(params).then((data) => {
      let branch_id = data.data[0].branch_id;
      let branchList = this.state.branchList;

      branchList.push(data.data[0]);

      if (button == "button") {
        this.setState(
          {
            branchList: branchList,
            button_action_id: "create_branch",
            branch_new_id: branch_id,
          },
          function () {
            this.modalCloseCreate();
            callback("success");
            //   setTimeout(
            //     function () {
            //         this.setState({  toster:{color:"",status:false,message:''} });
            //     }.bind(this),
            //     3000
            // );
          }
        );
      }

      if (button == "skills") {
        this.setState(
          { branchList: branchList, branch_new_id: branch_id },
          function () {
            // console.log(this.state.branchList);
            new Promise((resolve) => {
              let airules = this.state.AIRulesData;
              airules.map((obj, index) => {
                if (obj.intent_branch_id == "") {
                  return (obj.intent_branch_id = branch_id);
                }
              });

              resolve(airules);
            }).then((data) => {
              this.modalCloseCreate();
              this.setState({ AIRulesData: data });
              callback("success");
            });
          }
        );
      }

      if (button == "multiplechoice") {
        this.setState(
          { branchList: branchList, branch_new_id: branch_id },
          function () {
            this.modalCloseCreate();
          }
        );
      }

      if (button == "zipcoderules") {
        this.setState(
          {
            branchList: branchList,
            branch_new_id: branch_id,
            zipcodenewbranch_id: branch_id,
          },
          function () {
            this.modalCloseCreate();
            callback("success");
          }
        );
      }
    });
  };

  responseTimeChange = (e) => {
    this.setState({ response_time: e.target.value });
  };

  getUpdatedList = (branchList) => {
    this.setState({
      list:branchList
    })
  }

  finalSave = () => {
    if((this.state.bot_type === 'Voice' || this.state.bot_type === 'GEN_VOICE' || this.state.bot_type === 'Voice_Turbo')  && !this.state.voice_model){
      this.setState({
        voice_model_error : true
      })
      return;
    }

    this.setState({
      modalstatus: true,
      modaltitle: "Confirm Your Action",
      modalbody:
        "This is your final submission. Please click Yes after that you can preview your ChatBot.",
      modalindex: "finalsave",
    });
  };

  confirmFinalSave = () => {
    this.modalClose();
    this.setState({ loading: true });
    // console.log(this.state.editBranchType)
    let trainYourAiData = { ...this.state.trainYourAiData }

    let errorData = {};

    // validate squad setting
    const squadData = trainYourAiData?.squad_setting;
    if (squadData && Array.isArray(squadData)) {
      let isValidSquad = true;

      // Loop through the squadData array to validate each object
      squadData.forEach((squad, index) => {
        if (!squad.description || !squad.workspaceId) {
          isValidSquad = false;
          console.error(`Invalid data in squad setting at index ${index}:`, squad);
        }
      });

      if (!isValidSquad) {
        console.error('Validation failed for squad setting.');        
        this.setState({
          isAlertMessage: true,
          alertMessage: 'Some of your squad assistant fields are empty, all fields are required.'
        })

        window.scrollTo(0, 0);
        setTimeout(() => {
          this.setState({
            isAlertMessage: false,
            alertMessage: ''
          })
        }, 3000);
        return; // Stop the function execution if validation fails
      }
    }
    this.setState({ loading: true });
    // console.log(trainYourAiData)
    trainYourAiData.branch_id = this.state.editBranchType;
    let params = {
      workspace_id: this.state.workspace_id,
      response_time: this.state.response_time,
      voice_model: this.state.voice_model,
      transcriber_language: this.state.transcriber_language,
      trainYourAiData: trainYourAiData,
      bot_type:this.state.bot_type,
      status:this.state.userinfo.default_voice_workspace_id == null || this.state.userinfo.default_voice_workspace_id == 0  ? 0 : 1,
      back_status: this.state.userinfo.default_general_voice_workspace == null || this.state.userinfo.default_general_voice_workspace == 0 ? 0 : 1,

    };
    api.botbuilder.finalSave(params).then((data) => {
      this.loadData()
      // this.setState({ loading: false });
      // window.location.reload(false);
    });
  };

  saveQuestionResponse = (data, edit_id, callback) => {
    api.botbuilder
      .saveQuestionResponse({
        question: data,
        workspace_id: this.state.workspace_id,
        branch_id: this.state.active_branch_id,
        response_question_id: edit_id,
      })
      .then((data) => {
        let branch_detail = this.state.branchDetails;
        branch_detail.question_response_list = data.question_response_list;
        this.setState({ branch_detail: branch_detail });
        callback("success");
      });
  };

  saveWebhook = (data, edit_id, callback) => {
    api.botbuilder
      .saveZapWebhooks({
        webhooks: data,
        workspace_id: this.state.workspace_id,
        branch_id: this.state.active_branch_id,
        response_question_id: edit_id,
      })
      .then((data) => {
        console.log("df", data.data, data)
        let branch_detail = this.state.branchDetails;
        branch_detail.zapier_webhooks = data.data;
        this.setState({ branch_detail: branch_detail });
        callback("success");
      });
  };

  actionQuestionResponse = (action, params, index) => {
    if (action == "delete") {
      api.botbuilder.deleteQuestionResponse(params).then((data) => {
        let branch_detail = this.state.branchDetails;
        let activeindex = branch_detail.question_response_list.findIndex(
          (list) => list == index
        );

        branch_detail.question_response_list.splice(activeindex, 1);
        this.setState({ branch_detail: branch_detail }, function () {
          // let scrolltop=document.querySelector("#responsetoquestion").offsetTop - document.body.scrollTop;
          // window.scroll({top: scrolltop, left: 0, behavior: 'smooth' });
        });
      });
    }
    if (action == "edit") {
      this.setState(
        {
          activemodal: "responsequestion",
          button_action_id: params.question_id,
        },
        function () {
          let scrolltop =
            document.querySelector(".rightbotbuilder").offsetTop -
            document.body.scrollTop;
          window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
        }
      );
    }
  };

  confirmDeleteBranch = (index) => {
    this.setState({
      modalstatusforBranch: false,
    });
    api.botbuilder.deletewebhook(index).then((data) => {
      let branch_detail = this.state.branchDetails;

      // let activeindex = branch_detail.zapier_webhooks.findIndex(
      //   (list) => list == index
      // );

      branch_detail.zapier_webhooks.splice(index.index, 1);
      this.setState({ branch_detail: branch_detail }, function () {
        // let scrolltop=document.querySelector("#responsetoquestion").offsetTop - document.body.scrollTop;
        // window.scroll({top: scrolltop, left: 0, behavior: 'smooth' });
      });
    });

  }

  actionwebhookapi = (action, params, index) => {
    if (action == "delete") {
      this.setState({
        modalstatusforBranch: true,
        modaltitle: "Confirm Your Action",
        modalbody: "Are you sure you want to delete?",
        deletemodalid: params,
      });

    }
    if (action == "edit") {
      this.setState(
        {
          activemodal: "webhook_response",
          button_action_id: params.webhook_id,
        },
        function () {
          let scrolltop =
            document.querySelector(".rightbotbuilder").offsetTop -
            document.body.scrollTop;
          window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
        }
      );
    }
  };


  phoneFormat = (p) => {
    p = p.replace(/[^\d]*/gi, "");
    if (p.length <= 3) {
      return p;
    } else if (p.length > 3 && p.length < 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;
      return pp;
    } else if (p.length >= 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;

      let l40 = pp.length;
      let p40 = pp.substring(0, 8);
      p40 = p40 + "-";

      let p41 = pp.substring(8, l40);
      let ppp = p40 + p41;
      let maxphonelength = 13;
      let finalPhone = ppp.substring(0, maxphonelength);
      return finalPhone;
    }
  };

  saveMultipleChoice = (data, callback) => {
    let params = {
      branch_id: this.state.active_branch_id,
      data: data,
      workspace_id: this.state.workspace_id,
    };
    api.botbuilder.saveMultipleChoice(params).then((data) => {
      //callback('success');
      let branch_detail = this.state.branchDetails;
      branch_detail.multiple_choice_options = data.multiple_choice_options;
      this.setState({ branchDetails: branch_detail }, function () {
        callback("success");
      });
    });
  };
  MultipleChoiceAction = (tab, data) => {
    if (tab == "delete") {
      this.setState({
        modalstatus: true,
        modaltitle: "Confirm Your Action",
        modalbody: "Are you sure want to delete this ?",
        modalindex: "multiplechoicedelete",
      });
    }
  };

  confirmdeleteMultipleChoiceQuestion = () => {
    let params = {
      branch_id: this.state.active_branch_id,
      workspace_id: this.state.workspace_id,
    };
    api.botbuilder.deleteMultipleChoiceQuestionAll(params).then((data) => {
      //callback('success');
      let branch_detail = this.state.branchDetails;
      branch_detail.multiple_choice_options = data.multiple_choice_options;
      this.setState({ branchDetails: branch_detail, modalstatus: false });
    });
  };

  saveBranchLinks = (datainput, callback) => {
    let params = {
      branch_id: this.state.active_branch_id,
      workspace_id: this.state.workspace_id,
      data: datainput,
    };
    api.botbuilder.saveBranchLink(params).then((data) => {
      //callback('success');

      if (data.link_exist.length > 0) {
        callback(data);
      } else {
        let branch_detail = this.state.branchDetails;
        branch_detail.workspace_branch_url = data.workspace_branch_url;
        branch_detail.link_team_memebers = data.link_team_memebers;
        branch_detail.details.is_link_notification =
          datainput.is_link_notification;
        branch_detail.details.is_link_team_profile =
          datainput.is_link_team_profile;
        branch_detail.details.is_link_team_members =
          datainput.is_link_team_members;

        this.setState({ branchDetails: branch_detail }, function () {
          // console.log(this.state.branchDetails);
          callback(data);
        });
      }
    });
  };

  linkAction = (tab, datainput, index) => {
    if (tab == "delete") {
      let params = {
        branch_id: this.state.active_branch_id,
        workspace_id: this.state.workspace_id,
        branch_link_id: datainput.branch_link_id,
      };
      api.botbuilder.deleteBranchLink(params).then((data) => {
        let branch_detail = this.state.branchDetails;
        branch_detail.workspace_branch_url = data.workspace_branch_url;
        this.setState(
          { branchDetails: branch_detail, activemodal: "" },
          function () {
            //console.log(this.state.branchDetails);
          }
        );
      });
    }
  };

  liveChatRules = (action, data, index) => {
    if (action == "show") {
      let val = data.target.checked ? "1" : "0";

      let params = {
        val: val,
        workspace_id: this.state.workspace_id,
        branch_id: this.state.active_branch_id,
      };
      api.botbuilder.updateBranchTeamNotifyMembers(params).then((data) => {
        let branchDetails = this.state.branchDetails;
        branchDetails.details.live_chat_notification = val;
        if (data.status == true && val == 1) {
          this.setState({ livechatshow: true, branchDetails: branchDetails });
        } else {
          this.setState({ livechatshow: false, branchDetails: branchDetails });
          // console.log(data.message);
        }
      });
    }
    if (action == "close") {
      this.setState({ livechatshow: false });
    }
    if (action == "view") {
      this.setState({ livechatshow: true });
    }
  };

  loadTestView = (tab) => {
    if (tab == "open") {
      this.setState({ testView: true });
    }
    if (tab == "close") {
      this.setState({ testView: false });
    }
    if (tab == "load") {
      this.setState({ testView: false }, function () {
        this.setState({ testView: true });
      });
    }
  };

  jumpToBranch = (branch_id,branchType) => {
    if (typeof branch_id != "undefined") {
      var main = document.querySelector(".box-bg");
      main.scrollTop = 0;
      // console.log('chat br', branch_id);
      var element = document.querySelector(
        'div[data-branch="' + branch_id + '"]'
      );
      this.scrollIfNeeded(element, main, () => {
        this.editBranch(branch_id,branchType);
      });
    }
  };

  scrollIfNeeded = (element, container, callback) => {
    if (element.offsetTop < container.scrollTop) {
      container.scrollTop = element.offsetTop;
    } else {
      const offsetBottom = element.offsetTop + element.offsetHeight;
      const scrollBottom = container.scrollTop + container.offsetHeight;
      if (offsetBottom > scrollBottom) {
        container.scrollTop = offsetBottom - container.offsetHeight + 200;
      }
    }
    callback();
  };

  updateZipCodeBranchStatus = () => {
    this.setState({ zipcodenewbranch_id: "" });
  };

  updateDeleteZipRule = (type) => {
    this.setState({ checkzipcaptured: type });
  };

  onConfusedClick = (e, buttonIndex) => {
    this.setState({ confusedButtonActive: buttonIndex })
  }

  handleconfusedNextPrevious = (e, buttonIndex, type) => {
    if (type === 'next') {
      if (buttonIndex == 5) {
        return false;
      }
      this.setState({ confusedButtonActive: buttonIndex + 1 })
    }

    if (type === 'prev') {
      if (buttonIndex == 1) {
        return false;
      }
      this.setState({ confusedButtonActive: buttonIndex - 1 })
    }

  }

  handleCategoryCheck = (e, categoryId) => {

    let data = this.state.checkedCategory;
    let serviceData = this.state.checkedServiceCategory;
    let intentData = this.state.intent_data;
    const checkIndex = data.findIndex((v, i) => v == categoryId);

    if (checkIndex === -1) {
      data.push(categoryId);
    } else {
      data.splice(checkIndex, 1);
    }
    if (data.length === 0) {
      serviceData = [];
    } else {
      serviceData = intentData.subcategories.filter((item) => data.includes(item.cat_id));
    }
    this.setState({
      checkedCategory: data,
      checkedServiceCategory: serviceData
    })
  }

  handleServiceCategoryCheck = (e, serviceCatId) => {
    let serviceCatData = this.state.checkedServiceCategory;
    let itemIndex = serviceCatData.findIndex((item) => item.id === serviceCatId);
    serviceCatData[itemIndex].checked = !serviceCatData[itemIndex].checked
    this.setState({
      checkedServiceCategory: serviceCatData
    })
  }

  handleChangeisConfuseChanged = (value) => {
    this.setState({ isConfuseChanged: value })
  }

  settrainYourAiData = (v) => {
    this.setState({
      trainYourAiData: v
    });
  }
  closeinput=()=>{
    this.setState({showinputmodal:false,pause_text:''});
  }
  pauseText=(e)=>{
    this.setState({pause_text:e.target.value},()=>{
      let body=this.inputbody();
      let footer=this.inputfooter(false);
      this.setState({inputbody:body,pausetextfooter:footer});
    });
  }
  inputfooter=(type)=>{
    return (<div className="modal-footer">
    <button
      type="button"
      disabled={type}
      onClick={(e) => this.savePauseText(e)}
      className="btn  linear-gradient yr-submitbtn btn-sm"
    >
      <span>Save</span>
    </button>
  </div>);
  }
  inputbody=()=>{
    return(<div className="row">
       {this.state.loading && <div className="ui loading form"></div>}
       {this.state.Voicealert && <div style={{width:'100%'}} className={this.state.Voicealerttype=='green' ? 'alert alert-success':'alert alert-danger'}>{this.state.Voicealert}</div>}
        <div className="col-md-12">
          <input name="your-pause" value={this.state.pause_text}  className="form-control" onChange={(e)=>this.pauseText(e)} />
        </div>
    </div>);
  }
  openTextForm=(e)=>{
     let body=this.inputbody();
     let footer=this.inputfooter(false);
     this.setState({inputbody:body,showinputmodal:true,pausetextfooter:footer});
  }
  getdistinct=(arr1,arr2)=>{
    let arr=arr1.filter((ar)=>ar.name!==arr2[0].name);
      return [...arr2,...arr];
  }
    
  
  savePauseText=(e)=>{
    if(this.state.pause_text.length!==0){
      
       this.setState({loading:true},()=>{ 
        let body=this.inputbody();
        let footer=this.inputfooter(true);
        this.setState({inputbody:body,pausetextfooter:footer});
      });
      if(this.state.voice_model.length==0 || this.state.voice_model=="WEB"){
        this.setState({Voicealert:"Please select any voice model",Voicealerttype:'red',loading:false,},()=>{ 
          let body=this.inputbody();
        let footer=this.inputfooter(false);
        this.setState({inputbody:body,pausetextfooter:footer});
        });
  
        setTimeout(() => {
          this.setState({Voicealert:"",Voicealerttype:'red',loading:false,},()=>{
            let body=this.inputbody();
            let footer=this.inputfooter(false);
            this.setState({inputbody:body,pausetextfooter:footer});
          });
        }, 5000);
        return false;
      }
      
      api.botbuilder.addpauseText({text:this.state.pause_text,workspace_id:this.state.workspace_id,user_id:util_funs.encrypt_url(this.state.userinfo.user_id),model:this.state.voice_model}).then((data) => {
        try {
          // console.log(data)
          if (data.status) {
            let newpauses=[...data.voices,...data.user_pauses];
            this.setState({Voicealert:data.message,Voicealerttype:'green',loading:false,pauses_sound_list:newpauses,pause_text:'',pauses_list:data.user_pauses},()=>{ 
              let body=this.inputbody();
              let footer=this.inputfooter(false);
              let body1=this.viewpauses_listbody();
             
              this.setState({inputbody:body,pausetextfooter:footer,view_pause_body:body1});
            });
            setTimeout(() => {
              this.setState({Voicealert:'',pause_text:'',showinputmodal:false},()=>{ let body=this.pausessoundmodalbody();
                this.setState({puases_modalbody:body});
              });
            }, 3000); 
            
          }

        } catch (err) {
          // this.setState({ reportstatus: 'There is an error.' }
          //   , () => {
          //     this.setState({ notemodalbody: this.commentModalBody(transcript, workspaceid,bot_type) });
          //   });
          console.log(err);
        }
        setTimeout(() => {
          this.setState({ showmodal: false, reportstatus: '', notemodalbody: '', reportcommnet: "", reporterror: false });
        }, 5000);

      });
    }else{
      this.setState({Voicealert:"Please add pause text",Voicealerttype:'red',loading:false,},()=>{ 
        let body=this.inputbody();
              let footer=this.inputfooter(false);
              this.setState({inputbody:body,pausetextfooter:footer});
      });

      setTimeout(() => {
        this.setState({Voicealert:"",Voicealerttype:'red',loading:false,},()=>{ 
          let body=this.inputbody();
          let footer=this.inputfooter(false);
          this.setState({inputbody:body,pausetextfooter:footer});
        });
      }, 5000);
    }
  }
  
  saveNote = (e, transcript, workspaceid,bot_type) => {
    if (!this.state.canSubmit) {
      return false;
    }
    if (this.state.reportcommnet.length > 0) {
      this.setState({ canSubmit: false });
      let params = { workspace_id: workspaceid, transcript: JSON.stringify(transcript), comment: this.state.reportcommnet,bot_type:bot_type };
      api.botbuilder.savechatReport(params).then((data) => {
        try {
          // console.log(data)
          if (data.status) {
            this.setState({
              reportstatus: "Chat reported for training successfully.",
            }, () => {
              this.setState({ notemodalbody: this.commentModalBody(transcript, workspaceid,bot_type) });
            });
          }

        } catch (err) {
          this.setState({ reportstatus: 'There is an error.' }
            , () => {
              this.setState({ notemodalbody: this.commentModalBody(transcript, workspaceid,bot_type) });
            });
          console.log(err)
        }
        setTimeout(() => {
          this.setState({ showmodal: false, reportstatus: '', notemodalbody: '', reportcommnet: "", reporterror: false });
        }, 5000);

      });

    } else {
      let body = this.reportChat(transcript, workspaceid,bot_type);
      this.setState({ reporterror: true, notemodalbody: body })
    }

  }

  notemodalClose = () => {
    this.setState({
      showmodal: false,
      modaltitle: "",
      modalbody: "",
      modalindex: "",

    });
  }

  notemodalFooter = () => {
    return (
      <div className="modal-footer">
        {/* <button
              type="button"
              onClick={() => this.notemodalClose()}
              className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
              data-dismiss="modal"
          >
              <span>Cancel</span>
          </button> */}
        {/* <button
              type="button"
              onClick={() => this.saveNote()}
              className="btn linear-gradient linear-gradient yr-submitbtn btn-sm"
              data-dismiss="modal"
          >
              <span>Submit</span>
          </button> */}

      </div>
    );

  };


  handleChangereport = (e) => {
    let btn=true;
    if(e.target.value!==this.state.oldTxt){
       btn=false;
    }
    if(e.target.value===""){
      btn=true;
    }
    console.log(e.target.value,'length')
    this.setState({reportcommnet: e.target.value, reporterror: false, canSubmit:true,modalBtn:btn});
  }
  commentModalBody = (transcript, workspaceid,bot_type) => {
    return (<form id="notesForm1" method="post">
      <Message
        color="teal"
        style={{
          display: this.state.reportstatus?.length ? "block" : "none",
          marginTop: 30,
        }}
      >
        {this.state.reportstatus}
      </Message>

      Please provide additional details why this chat needs training?
      <div className="form-group">

        <textarea rows="5" onChange={(e) => this.handleChangereport(e)} name="reportcommnet" id="notes" placeholder="Write notes here ..." defaultValue={this.state.reportcommnet} className={
          this.state.reporterror
            ? "form-control is-invalid"
            : "form-control"}></textarea>
        {this.state.reporterror && <CustomErrorField
          message="This field is required"
          id="help-error"
          className="error invalid-feedback"
        />
        }
      </div>
      <div className="form-group">
        <button type="button" style={{
          width: '100%',
          marginTop: '20px'
        }} onClick={(e) => this.saveNote(e, transcript, workspaceid,bot_type)} disabled={this.state.modalBtn} className="btn linear-gradient yr-submitbtn btn-md sbold btn-block">Submit</button>
      </div>
    </form>);
  }
  reportChat = (transcript, workspaceid,bot_type) => {
    // this.loadTestView('close');
    this.setState({ showmodal: true, }, () => {
      let body = this.commentModalBody(transcript, workspaceid,bot_type);
      this.setState({ notemodalbody: body });
    });


  }

  updateBranchDetailsAfterCallFarword = (data) => {
    let branchDetail = this.state.branchDetails;
    branchDetail.callForwardRule = data;
    this.setState({ branchDetails: branchDetail });
  }

  voiceModelChange = (e) => {
    const selectedAudio = e.target.value;
    if (selectedAudio) {
      const voiceList = this.state.voice_model_list;
      const voiceModel = voiceList.filter((e) => e.voice_id === selectedAudio)
      this.setState({
        voice_model : voiceModel[0].voice_id,
        voice_model_sample: voiceModel[0].preview_url,
        voice_model_error: false
      })
    }else{
      this.setState({
        voice_model : '',
        voice_model_sample: '',
        voice_model_error: false
      })
    }
    
  }

  onChangeTransciberLanguage = (e) => {
    this.setState({
      transcriber_language: e.target.value
    })
  }

  onChangeLLMGeneratedMessage = (e) => {
    const branchDetails = this.state.branchDetails;
    branchDetails.details.is_llm_generated_message = e.target.checked ? 'on' : 'off';
    this.setState({
      branchDetails: branchDetails
    });
  }

  render() {
    return (
      <div
        className="kt-container kt-body  kt-grid kt-grid--ver botbuilderdetailbody"
        id="kt_body"
      >
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-content kt-grid__item kt-grid__item--fluid">
            <div className="yr-body-p botbuilderdetails">
              <div className="row">
                <div className="wsab-flex">
                  <div
                    // className="alert workspace-note"
                    className={
                      this.state.alert_message_display == true
                        ? "alert workspace-note"
                        : "workspace-note"
                    }
                    role="alert"
                  >
                    {this.state.alert_message_display == true && (
                      <span>
                        <strong>Note! &nbsp;</strong> To apply changes, please
                        click on the "Save" button to prevent losing any work.
                      </span>
                    )}{" "}
                  </div>
                  <div
                    // className="alert workspace-note"
                    className={
                      this.state.isAlertMessage == true
                        ? "alert workspace-note"
                        : "workspace-note"
                    }
                    role="alert"
                  >                    
                      <span>
                        <strong>{this.state.alertMessage}</strong> 
                      </span>                    
                  </div>
                      
                        <div className="right-btns">
                        
                          <button
                            type="reset"
                            className="btn linear-gradient-cancel yr-cancelbtn mg-r"
                            onClick={() => this.loadTestView("open")}
                          >
                            <span> Click to Test! </span>
                          </button>
                          <button
                            type="submit"
                            className="btn linear-gradient yr-submitbtn"
                            onClick={() => this.finalSave()}
                          >
                            Save
                          </button>
                        </div>
                 

                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  {this.state.loading && (
                    <div className="ui loading form"></div>
                  )}
                  <div>
                    <div className="kt-portlet shadow-none">
                      <div className="kt-portlet__body workspace-automation px-0">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="form-group row">
                              <div className="col-lg-6 col-md-6">
                                {this.state.bot_type !== 'GDF' && this.state.bot_type !== 'Voice' && this.state.bot_type !== 'Voice_Turbo' && this.state.bot_type !== 'GEN_VOICE' &&
                                  <>
                                    <label className="col-form-label bold mar-top-0 inline-block">
                                      Delay time between responses:
                                    </label>

                                    <select
                                      className="form-control inline-block"
                                      name="response_time"
                                      value={this.state.response_time}
                                      onChange={(e) => this.responseTimeChange(e)}
                                    >
                                      <option value="">Select Time</option>
                                      <option value="3">3 Seconds</option>
                                      <option value="4">4 Seconds</option>
                                      <option value="5">5 Seconds</option>
                                      <option value="10">10 Seconds</option>
                                      <option value="20">20 Seconds</option>
                                      <option value="60">1 Minute</option>
                                      <option value="300">5 Minute</option>
                                    </select>
                                  </>
                                }
                                {
                                  (this.state.bot_type === 'Voice' || this.state.bot_type === 'GEN_VOICE' || this.state.bot_type === 'Voice_Turbo') && <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                  <div className="col-md-6">
                                  <div className="form-group">
                                  {this.state.voice_model_error && 
                                    <p className="text-danger"><b>Please select voice model.</b></p>
                                  }
                                  <label className="col-form-label bold mar-top-0">
                                      Select voice model:
                                    </label>
                                        <select
                                          className="form-control"
                                          name="voice_model"
                                          value={this.state.voice_model}
                                          onChange={(e) => this.voiceModelChange(e)}
                                        >
                                          <option value="">Select Voice</option>
                                          {this.state?.voice_model_list && this.state.voice_model_list.map((item) => {
                                            const nameParts = item.name.split('-');
                                            return (
                                              <option key={item.voice_id} value={item.voice_id}>
                                                {nameParts.length > 1 ? nameParts[0].trim() : item.name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                    <small>If you change the voice model, please re-save all branches after saving the workspace to synchronize the previously saved audio of branches.</small>
                                  </div>
                                  </div>
                                  <div className="col-md-6">
                                      
                                    {this.state.voice_model && this.state.voice_model_sample && 
                                      <audio style={{marginBottom: 16}} key={`recording_play_${this.state.voice_model}`} id={`recording_play_${this.state.voice_model}`} controls>
                                        <source src={`${this.state.voice_model_sample}`} />
                                        <source src={`${this.state.voice_model_sample}`} />
                                        Your browser does not support the audio element.
                                      </audio>
                                    }
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Transcriber language</label>
                                        <select onChange={(e) => this.onChangeTransciberLanguage(e)} className="form-control" name="transcriberlanguage" value={this.state.transcriber_language}>
                                          {transcriberLanguages.map((tl, index) => (
                                            <option key={tl.code} value={tl.code}>{tl.name}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                }
                                 {(this.state.bot_type === 'Voice' || this.state.bot_type === 'GEN_VOICE' ) && <div className="row" style={{display: 'flex', alignItems: 'center'}}>
                                  <div className="col-md-6">
                                  <button
                                    type="button"
                                    className="btn linear-gradient-cancel yr-cancelbtn mg-r"
                                    onClick={(e) =>this.openpuasesconfig(e)
                                    }
                                  >
                                    <span> Customize grammatical pause </span>
                                  </button>
                                  </div>
                                  </div>}
                              </div>
                              {(!this.state.loading && this.state.bot_type != 'Voice_Turbo') && (
                                <div className="col-lg-6 col-md-6 text-align-right mob-align-left">
                                 {!['GEN_WEB','GEN_VOICE',''].includes(this.state.bot_type) && <button
                                    type="button"
                                    className="btn linear-gradient-cancel yr-cancelbtn mg-r"
                                    onClick={(e) =>
                                      this.show_user_iframe(
                                        this.state.userinfo.user_id,
                                        this.state.workspace_id
                                      )
                                    }
                                  >
                                    <span> View user perspective </span>
                                  </button> } 
                                  {(this.state.userinfo.default_workspace_id !=
                                    "0" &&
                                    this.state.userinfo.default_workspace_id !=
                                    null &&
                                    this.state.userinfo.default_workspace_id ==
                                    this.state.custom_workspace_id &&
                                    this.state.bot_type == "WEB") ||
                                    (this.state.userinfo
                                      .default_sms_workspace_id != "0" &&
                                      this.state.userinfo
                                        .default_sms_workspace_id != null &&
                                      this.state.userinfo
                                        .default_sms_workspace_id ==
                                      this.state.custom_workspace_id &&
                                      this.state.bot_type == "SMS") ||
                                    (this.state.userinfo
                                      .default_fb_workspace_id != "0" &&
                                      this.state.userinfo
                                        .default_fb_workspace_id != null &&
                                      this.state.userinfo
                                        .default_fb_workspace_id ==
                                      this.state.custom_workspace_id &&
                                      (this.state.bot_type == "FB" || this.state.bot_type == 'FACEBOOK')) || (this.state.userinfo
                                        .default_gdf_workspace_id != "0" &&
                                        this.state.userinfo
                                          .default_gdf_workspace_id != null &&
                                        this.state.userinfo
                                          .default_gdf_workspace_id ==
                                        this.state.custom_workspace_id &&
                                        this.state.bot_type == "GDF") || (this.state.userinfo
                                          .default_voice_workspace_id ==
                                          this.state.custom_workspace_id &&
                                          (this.state.bot_type == "Voice" ) ) || (this.state.userinfo
                                            .default_general_web_workspace ==
                                            this.state.custom_workspace_id &&
                                            this.state.bot_type == "GEN_WEB") || (this.state.userinfo
                                              .default_general_voice_workspace ==
                                              this.state.custom_workspace_id &&
                                              this.state.bot_type == "GEN_VOICE") ? (
                                    <button
                                      type="button"
                                      className="btn linear-gradient yr-submitbtn"
                                      onClick={(this.state.bot_type=='GEN_WEB' || this.state.bot_type=='GEN_VOICE') ? (e) =>  this.onDeploy('Remove') : (e) =>  this.onDeploy('Open')}
                                      >
                                      Remove
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn linear-gradient yr-submitbtn"
                                      onClick={(this.state.bot_type=='GEN_WEB' || this.state.bot_type=='GEN_VOICE') ? (e) =>  this.onDeploy('Deploy') : (e) =>  this.onDeploy('Open')}
                                    >
                                      Deploy
                                    </button>
                                  )}
                                 
                                </div>
                              )}
                               {( this.state.bot_type == 'Voice_Turbo') && 
                                  <div className="col-lg-6 col-md-6 text-align-right mob-align-left">
                                        <button  
                                        type="button"
                                        className="btn linear-gradient yr-submitbtn"
                                        onClick={(e) =>  this.onDeploy('Open')}
                                      >
                                        {this.state.userinfo.voice_turbo_default_workspace_id ==  atob(this.state.workspace_id) ? "Re-Deploy" : "Deploy" }
                                      </button>
                                    <div/>
                                </div>
                               }
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.dialogDeploy
                              ? "modal fade show"
                              : "modal fade"
                          }
                          data-backdrop="static"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="staticBackdrop"
                          aria-hidden="true"
                          style={{
                            display: this.state.dialogDeploy ? "block" : "none",
                          }}
                        >
                          <div
                            className="modal-dialog modal-dialog-centered"
                            role="document"
                            style={{ maxWidth: 600 }}
                          >
                            {this.state.dialogDeployLoading && (
                              <div className="ui loading form"></div>
                            )}
                            <div className="modal-content">
                              <button
                                type="button"
                                className="close linear-gradient"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => this.onDeploy("Close")}
                              >
                                X
                              </button>
                              <div className="modal-header">
                                <h5 className="modal-title">
                                  {this.state.dialogDeployTitle != ""
                                    ? this.state.dialogDeployTitle
                                    : "Confirm your action"}
                                </h5>
                              </div>
                              <div className="modal-body">
                                <div className="px-4">
                                  {this.state.dialogDeployMessage && (
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: this.state.dialogDeployMessage,
                                      }}
                                    ></p>
                                  )}
                                </div>
                              </div>
							  {
								this.state.actionforgenaction == '' ?  
									<div className="modal-footer">
									<button
									type="button"
									className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
									onClick={() => this.onDeploy("Close")}
									data-dismiss="modal"
									>
									<span>
										{this.state.deployAction != ""
										? "No"
										: "Close"}
									</span>
									</button>
									{this.state.deployAction != "" && (
									<button
										type="button"
										className="btn linear-gradient yr-submitbtn btn-sm"
										onClick={() =>
										this.onDeploy(this.state.deployAction)
										}
									>
										Yes
									</button>
									)}
								</div> : <div className="modal-footer">
                                <button
                                  type="button h"
                                  className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                                  onClick={() => this.onDeploy("Close")}
                                  data-dismiss="modal"
                                >
                                  <span>
                                    {this.state.deployAction != ""
                                      ? "No"
                                      : "Close"}
                                  </span>
                                </button>
                                {this.state.deployAction != "" && (
                                  <button
                                    type="button er"
                                    className="btn linear-gradient yr-submitbtn btn-sm"
                                    onClick={() =>
                                      this.geralbotOndeploy()
                                    }
                                  >
                                    Yes
                                  </button>
                                )}
                              </div>
							  }
                              
                            </div>
                          </div>
                        </div>

                        {!this.state.loading && (
                          <div className="row">
                            <div className="col-lg-3 branchlistbuilder">
                              <BranchList
                                indextime={new Date()}
                                branchList={this.state.branchList}
                                branch_new_id={this.state.branch_new_id}
                                updateList={this.updateList}
                                updateBranchList={this.updateBranchList}
                                editBranch={this.editBranch}
                                addBranch={this.addBranch}
                                review_links={this.state.review_links}
                                contextChangedFlag={this.state.isConfuseChanged}
                                refeshData={this.loadData}
                                getUpdatedList={this.getUpdatedList}
                                bot_type={this.state.bot_type}
                              />
                            </div>
                            {this.state.branchType ?
                              <div
                                className="col-lg-9 workSpaceAddField yr-chat-box-row "
                                style={{ display: "block" }}
                              >
                                <ConfusedBranchDetails
                                  intent_data={this.state.gpt_intent_data}
                                  workspace_id={this.state.workspace_id}
                                  editBranchType={this.state.editBranchType}
                                  randtime={Math.floor(Math.random() * 100000)}
                                  branch_id={this.state.active_branch_id}
                                  isConfuseChanged={this.handleChangeisConfuseChanged}
                                  isConfuseChangedV={this.state.isConfuseChanged}
                                  trainYourAiData={this.settrainYourAiData}
                                  integrationBranchId={this.state.integrationId}
                                  primaryBranchId={this.state}
                                  turboList={this.state.turboList}
                                  bot_type={this.state.bot_type}
                                />
                              </div> :
                              <>
                                <div
                                  className="col-lg-5 workSpaceAddField yr-chat-box-row "
                                  style={{ display: "block" }}
                                >
                                  <BranchDetail
                                    openTab={this.openTab}
                                    branchDetails={this.state.branchDetails}
                                    captured_list={this.state.captured_list}
                                    saveBranch={this.saveBranch}
                                    buttonAction={this.buttonAction}
                                    skillsAction={this.skillsAction}
                                    button_action_id={this.state.button_action_id}
                                    saveButton={this.saveButton}
                                    actionTeamMembers={this.actionTeamMembers}
                                    textAreaAction={this.textAreaAction}
                                    loadingaction={this.state.loadingaction}
                                    toster={this.state.toster}
                                    bot_type={this.state.bot_type}
                                    workspace_id={this.state.workspace_id}
                                    branchList={this.state.branchList}
                                    actionQuestionResponse={
                                      this.actionQuestionResponse
                                    }
                                    actionwebhookapi={
                                      this.actionwebhookapi
                                    }
                                    countryCodeLists={this.state.countryCodeLists}
                                    defaultCountryCodeLists={
                                      this.state.defaultCountryCodeLists
                                    }
                                    MultipleChoiceAction={this.MultipleChoiceAction}
                                    linkAction={this.linkAction}
                                    liveChatRules={this.liveChatRules}
                                    indextime={new Date()}
                                    editortrue={this.state.editortrue}
                                    checkzipcaptured={this.state.checkzipcaptured}
                                    updateDeleteZipRule={this.updateDeleteZipRule}
                                    popupClose={this.popupClose}
                                    review_links={this.state.review_links}
                                    contact_bucket={this.state.contact_bucket}
                                    checkedValue={this.state.checkedValue}
                                    checkedValueID={this.state.checkedValueID}
                                    branchType={this.state.branchType}
                                    updateBranchDetailsCall={this.updateBranchDetailsAfterCallFarword}
                                    onChangeLLMGeneratedMessage={this.onChangeLLMGeneratedMessage}
                                  />
                                </div>
                                <div className="col-lg-4 rightbotbuilder">
                                  {this.state.activemodal == "button" && (
                                    <AddButton
                                      bot_type={this.state.bot_type}
                                      saveButton={this.saveButton}
                                      popupClose={this.popupClose}
                                      branchList={this.state.branchList}
                                      countryCodeLists={this.state.countryCodeLists}
                                      defaultCountryCodeLists={
                                        this.state.defaultCountryCodeLists
                                      }
                                      location_list={this.state.location_list}
                                      buttondata={
                                        this.state.branchDetails.branch_buttons
                                      }
                                      button_action_id={this.state.button_action_id}
                                      createNewBranch={this.createNewBranch}
                                      branch_new_id={this.state.branch_new_id}
                                      review_links={this.state.review_links}
                                      workspace_id={this.state.workspace_id}
                                      branch_id={this.state.active_branch_id}
                                    />
                                  )}

                                  {this.state.activemodal == "call_forward" && (
                                    <AddCallForward
                                      popupClose={this.popupClose}
                                      branchDetails={this.state.branchDetails}
                                      button_action_id={this.state.button_action_id}
                                      branchList={this.state.branchList}
                                      countryCodeLists={this.state.countryCodeLists}
                                      defaultCountryCodeLists={
                                        this.state.defaultCountryCodeLists
                                      }
                                      updateBranchDetails={this.updateBranchDetailsAfterCallFarword}
                                      bot_type={this.state.bot_type}
                                    />
                                  )}
                                  {this.state.activemodal == "branch_call_time" && (
                                    <ScheduledBranchCall
                                      popupClose={this.popupClose}
                                      branch_id={this.state.active_branch_id}
                                      workspace_id={this.state.workspace_id}
                                      bot_type={this.state.bot_type}
                                    />
                                  )}
                                  {this.state.activemodal == "skills" && (
                                    <AddSkills
                                      saveButton={this.saveButton}
                                      popupClose={this.popupClose}
                                      branchList={this.state.branchList}
                                      aiRules={this.state.AIRulesData}
                                      button_action_id={this.state.button_action_id}
                                      intent_data={this.state.intent_data}
                                      saveAIRules={this.saveAIRules}
                                      aiRulesList={this.state.branchDetails.ai_list}
                                      updateAIRules={this.updateAIRules}
                                      createNewBranch={this.createNewBranch}
                                      branch_new_id={this.state.branch_new_id}
                                      indextime={new Date()}
                                    />
                                  )}
                                  {this.state.activemodal == "webhook_response" && (
                                    <AddWebhook
                                      saveButton={this.saveButton}
                                      popupClose={this.popupClose}
                                      saveWebhook={this.saveWebhook}
                                      branchDetails={this.state.branchDetails}
                                      button_action_id={this.state.button_action_id}
                                      branchList={this.state.branchList}
                                    />
                                  )}
                                  {this.state.activemodal == "responsequestion" && (
                                    <QuestionResponse
                                      saveButton={this.saveButton}
                                      popupClose={this.popupClose}
                                      questionResponsAction={
                                        this.questionResponsAction
                                      }
                                      button_action_id={this.state.button_action_id}
                                      saveQuestionResponse={
                                        this.saveQuestionResponse
                                      }
                                      branchDetails={this.state.branchDetails}
                                    />
                                  )}

                                  {this.state.activemodal == "links" && (
                                    <AddLinks
                                      popupClose={this.popupClose}
                                      indextime={new Date()}
                                      branchDetails={this.state.branchDetails}
                                      countryCodeLists={this.state.countryCodeLists}
                                      defaultCountryCodeLists={
                                        this.state.defaultCountryCodeLists
                                      }
                                      saveBranchLinks={this.saveBranchLinks}
                                      workspace_id={this.state.workspace_id}
                                      branch_id={this.state.active_branch_id}
                                    />
                                  )}

                                  {this.state.activemodal == "multiplechoice" && (
                                    <MultipleChoice
                                      saveMultipleChoice={this.saveMultipleChoice}
                                      indextime={new Date()}
                                      popupClose={this.popupClose}
                                      branchDetails={this.state.branchDetails}
                                      branchList={this.state.branchList}
                                      createNewBranch={this.createNewBranch}
                                      branch_new_id={this.state.branch_new_id}
                                      create_modal_status={
                                        this.state.create_modal_status
                                      }
                                    />
                                  )}

                                  {this.state.activemodal == "zipcoderules" && (
                                    <ZipCodeRules
                                      indextime={new Date()}
                                      popupClose={this.popupClose}
                                      branchDetails={this.state.branchDetails}
                                      branchList={this.state.branchList}
                                      createNewBranch={this.createNewBranch}
                                      branch_new_id={this.state.branch_new_id}
                                      branch_id={this.state.active_branch_id}
                                      workspace_id={this.state.workspace_id}
                                      defaultCountryCodeLists={this.state.defaultCountryCodeLists}
                                      countryCodeLists={this.state.countryCodeLists}
                                      create_modal_status={
                                        this.state.create_modal_status
                                      }
                                      zipcodenewbranch_id={
                                        this.state.zipcodenewbranch_id
                                      }
                                      updateZipCodeBranchStatus={
                                        this.updateZipCodeBranchStatus
                                      }
                                      updateDeleteZipRule={this.updateDeleteZipRule}
                                    />
                                  )}

                                  {this.state.activemodal == "transcriptrule" && (
                                    <TranscriptRule
                                      indextime={new Date()}
                                      popupClose={this.popupClose}
                                      branchDetails={this.state.branchDetails}
                                      branchList={this.state.branchList}
                                      saveButton={this.saveButton}
                                      branch_new_id={this.state.branch_new_id}
                                      branch_id={this.state.active_branch_id}
                                      bot_type={this.state.bot_type}
                                    />
                                  )}

                                  {this.state.activemodal == "dataParse" && (
                                    <DataParseRule
                                      indextime={new Date()}
                                      popupClose={this.popupClose}
                                      bot_type={this.state.bot_type}
                                      workspace_id={this.state.workspace_id}
                                    />
                                  )}

                                  {this.state.activemodal == 'gtm_trigger_id' &&
                                    <AddGtmTriggerId
                                      popupClose={this.popupClose}
                                      branchId={this.state.active_branch_id}
                                    />
                                  }



                                  <CreateBranch
                                    show={this.state.create_modal_status}
                                    onclose={this.modalCloseCreate}
                                    saveNewBranch={this.saveNewBranch}
                                  ></CreateBranch>

                                  {this.state.livechatshow && (
                                    <LiveChatRule
                                      livechatshow={this.state.livechatshow}
                                      liveChatRules={this.liveChatRules}
                                      indextime={new Date()}
                                      branch_id={this.state.active_branch_id}
                                      workspace_id={this.state.workspace_id}
                                      branchDetails={this.state.branchDetails}
                                      countryCodeLists={this.state.countryCodeLists}
                                      defaultCountryCodeLists={
                                        this.state.defaultCountryCodeLists
                                      }
                                    />
                                  )}


                                </div>
                              </>
                            }
                          </div>
                        )}
                        {this.state.testView && (
                          <TestView
                            loadTestView={this.loadTestView}
                            workspace_id={this.state.workspace_id}
                            jumpToBranch={this.jumpToBranch}
                            review_links={this.state.review_links}
                            reportChat={this.reportChat}
                            bot_type={this.state.bot_type}
                          />
                        )}
                        <Modal
                          show={this.state.modalstatus}
                          footer={this.modalFooter(this.state.modalindex)}
                          onclose={this.modalClose}
                          title={this.state.modaltitle}
                          body={this.state.modalbody}
                          modelindex={this.state.modelzindex}
                        />
                         <Modal
                          show={this.state.view_pause_modal}
                          footer={(
                            <div className="modal-footer">
                              <button
                                type="button"
                                onClick={() => this.modalpauselistClose()}
                                className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                                data-dismiss="modal"
                              >
                                <span>Cancel</span>
                              </button>
                            </div>
                          )}
                          onclose={this.modalpauselistClose}
                          title="View Pause List"
                          body={this.state.view_pause_body}
                          modelindex="56696"
                        />
                        <Modal
                          show={this.state.finalWorkspaceSaveModalFlag}
                          footer={(<div className="modal-footer">
                            <button
                              type="button"
                              onClick={() => this.finalWorkspaceSaveModal()}
                              className="btn  linear-gradient yr-submitbtn btn-sm"
                            >
                              <span>Close</span>
                            </button>
                          </div>)}
                          onclose={this.finalWorkspaceSaveModal}
                          title={"Context Branch"}
                          body={"Please save your context branch changes before saving the workspace."}
                        />
                        <Modal
                          show={this.state.showmodal}
                          footer={this.notemodalFooter()}
                          onclose={this.notemodalClose}
                          title='Report Chat For Training'
                          body={this.state.notemodalbody}
                        />
                        <Modal
                          show={this.state.modalstatusforBranch}
                          footer={this.modalFooterforBranch(this.state.modalindex)}
                          onclose={this.modalClose}
                          title={this.state.modaltitle}
                          body={this.state.modalbody}
                        />
                         <Modal
                          show={this.state.puases_modal}
                          footer={this.state.puases_modalfooter}
                          onclose={this.closepoauses_model}
                          title={this.state.modaltitle}
                          body={this.state.puases_modalbody}
                          title="Customize Grammatical Pause"
                        />
                         <Modal
                          show={this.state.showinputmodal}
                          footer={this.state.pausetextfooter}
                          onclose={this.closeinput}
                          title='Add pause text'
                          body={this.state.inputbody}
                        />


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PageMessage: state.page.message,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
})(BotBuilderDetail);
